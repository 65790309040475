import React from 'react'
import TopNav from '../../../Components/TopNav'
import { useNavigate } from 'react-router'
import SideNav from '../../../Components/SideNav'

export default function AddEmployee() {

    const navigate = useNavigate()

    return (
        <div>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">Add Employee </h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                              <div className="me-2">
                                    <button onClick={() => navigate('/searchEmployee')} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-search"></i> Search Employee</button>
                                </div>
                            </div>
                        </div>

                        <label></label>
                        <div className="table-responsive">

                        </div>
                    </main>
                </div>
            </div >
        </div >
    )
}