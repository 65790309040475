import React, { useState } from 'react'
import API from '../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../Common/Toast'
import { setToken } from '../../Common/Storage'
import { useDispatch } from 'react-redux';
import { userInfo } from '../../Common/Redux/Actions';
import { useNavigate } from 'react-router-dom'
import { BUILD_DATE, BUILD_VERSION } from '../../config';

export default function ForgotPassword() {

    const navigate = useNavigate()
    const [data, setData] = useState();

    const handleForgotPassword = (e) => {
        e.preventDefault()
        API.post(`/login_block/user_verify`, data,
            {}, false).then(res => {
                toastSuccess(res.message)
                handleOtpInput(res.data)

            }).catch(err => {
                toastWarning(err.message)
            })

    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleOtpInput = (data) => {
        navigate('/userOtpInput', { state: data });
    };
    return (
        <div className='login-header'>
            <div className='login-container text-center'>
                <main className="form-signin w-100 m-auto">
                    <form onSubmit={(e) => handleForgotPassword(e)}>
                        <img className="mb-4" src="./images/emp_logo.png" alt="" width="300" height="70" />
                        {/* <h1 className="h3 mb-3 fw-normal">Welcome back to EMS!</h1> */}
                        <h5>Forgot Password</h5>
                        <div className="form-floating">
                            <input name="username" type="text" className="form-control" id="floatingInput" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingInput">Username</label>
                        </div>


                        <div className="checkbox mb-3">

                            <label style={{ float: 'right', cursor: 'pointer' }} onClick={() => navigate('/')}>
                                Login
                            </label>
                        </div>
                        <button className="w-100 mt-4 mb-1 btn btn-sm btn-primary btn-color mt-1" type="submit">Submit</button>
                        <p className="mt-1 mb-3 text-body-secondary">V {BUILD_VERSION} {BUILD_DATE} &copy; {`Novisync Inc ${new Date().getFullYear()}`}</p>
                    </form>
                </main>
            </div>
        </div>
    )
}
