import React, { useEffect, useState } from 'react'
import { removeToken } from '../../Common/Storage';
import API from '../../Common/Network/API';
import { toastWarning } from '../../Common/Toast';

export default function SearchBar(props) {

    const [recentSearchData, setRecentSearchData] = useState([])
    const [searchData, setSearchData] = useState({
        name: null,
        rank: null,
        location: null
    })


    useEffect(() => {
        gerRecentSearches()
    }, [])

    const gerRecentSearches = () => {
        API.get('/search_block/getRecentSearches', true)
            .then((result) => {
                setRecentSearchData(result.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleGlobalSearch = () => {
        API.post(`search_block/globalSearch`, searchData, {}, true)
            .then((result) => {
                props.getSearchResult(result.data, searchData)
            })
            .catch((err) => {
                toastWarning(err.message)
                console.log(err)
            })
    }

    const handleSearchChange = (e) => {
        setSearchData({
            ...searchData, [e.target.name]: e.target.value
        })
    }

    return (
        <div style={{
            background: '#dce7eba3',
            height: '120px',
        }}>
            <div>
                <button
                    onClick={() => {
                        removeToken('empAddToken');
                        removeToken('empName');
                        window.location.href = '/personalDetails';
                    }}
                    type="button"
                    className="btn btn-sm btn-primary m-2"
                >
                    <i className="bi bi-plus-circle"></i> Add Employee
                </button>
            </div>
            <div className=" container">
                <div>
                    <form>
                        <div className='search-component'>
                            <div className='col-12 row'>
                                <div className='col-3 p-0'>
                                    <input name="name" type="search" className='form-control input-height' placeholder='Name' onChange={(e) => handleSearchChange(e)} />
                                </div>
                                <div className='col-3 p-0'>
                                    <input name="rank" type="search" className='form-control input-height' placeholder='Rank' onChange={(e) => handleSearchChange(e)} />
                                </div>
                                <div className='col-3 p-0'>
                                    <input name="location" type="search" className='form-control input-height' placeholder='Location' onChange={(e) => handleSearchChange(e)} />
                                </div>
                                <div className='col-3 m-0'>
                                    <button onClick={() => handleGlobalSearch()} type="button" className='btn btn-primary input-height-button'><i class="bi bi-search"></i> Search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}