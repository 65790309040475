import React, { useState } from 'react'
import API from '../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../Common/Toast'
import { setToken } from '../../Common/Storage'
import { useDispatch } from 'react-redux';
import { userInfo } from '../../Common/Redux/Actions';
import { useNavigate } from 'react-router-dom'
import { BUILD_DATE, BUILD_VERSION } from '../../config';

export default function UserLogin() {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState()

    const handleLogin = (e) => {
        e.preventDefault()
        API.post(`/login_block/user_login`, data,
            {}, false).then(res => {
                // toastSuccess('Loggedin!')
                setToken('auth', res.token)
                dispatch(userInfo(res));
                navigate('/dashboard')
            }).catch(err => {
                toastWarning(err.message)
            })
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
      };
    const handleForgotPassword = () => {
        navigate('/userforgotPassword');
      };
    return (
        <div className='login-header'>
            <div className='login-container text-center'>
                <main className="form-signin w-100 m-auto">
                    <form onSubmit={(e) => handleLogin(e)}>
                        <img className="mb-4" src="./images/emp_logo.png" alt="" width="300" height="70" />
                        {/* <h1 className="h3 mb-3 fw-normal">Welcome back to EMS!</h1> */}
                        <h5>User Sign in</h5>
                        <div className="form-floating">
                            <input name="username" type="text" className="form-control" id="floatingInput" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingInput">Username</label>
                        </div>
                        <div className="form-floating">
                            <input name="password" type={showPassword ? 'text' : 'password'} className="form-control" id="floatingPassword" onChange={(e) => handleChange(e)} required/>
                            <span className="password-toggle-icon"
                            style={{ position: 'absolute', top: '50%', right: '0',transform: 'translateY(-50%)',cursor: 'pointer', padding: '8px',}}
                            onClick={handleTogglePasswordVisibility} >
                            {showPassword ? (
                                <i className="bi bi-eye"></i>
                            ) : (
                                <i className="bi bi-eye-slash"></i>
                            )}</span>  
                        <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="checkbox mb-3">
                            <label style={{ float: 'left' }}>
                                <input type="checkbox" value="remember-me" /> Remember Me
                            </label>
                            <label style={{ float: 'right', cursor: 'pointer' }} onClick={handleForgotPassword} >
                                Forgot Password?
                            </label>
                        </div>
                        <button className="w-100 mt-4 mb-1 btn btn-sm btn-primary btn-color mt-1" type="submit">Sign in</button>
                        <p className="mt-1 mb-3 text-body-secondary">V {BUILD_VERSION} {BUILD_DATE} &copy; {`Novisync Inc ${new Date().getFullYear()}`}</p>
                    </form>
                </main>
            </div>
        </div>
    )
}
