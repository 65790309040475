export const menuArray = [
    {
        id: 1, name: "PERSONAL DETAILS", path: '/personalDetails', component: "PersonalDetails"
    },
    {
        id: 2, name: "PUNISHMENT", path: '/punishment', component: "Punishment"
    },
    {
        id: 3, name: "COURSES", path: '/courses', component: "Courses"
    },
    {
        id: 4, name: "CADRES", path: '/cadres', component: "CADRES"
    },
    {
        id: 5, name: "ERE's", path: '/eres', component: "ERES"
    },
    {
        id: 6, name: "LOAN DETAILS", path: '/loanDetails', component: "LoanDetails"
    },
    {
        id: 7, name: "PROMOTION DETAILS", path: '/promotionDetails', component: "PromotionDetails"
    },
    {
        id: 8, name: "AFPP", path: '/afpp', component: "AFPP"
    },
    {
        id: 9, name: "MACP", path: '/macp', component: "MACP"
    },
    {
        id: 10, name: "SERVICE RETN BD", path: '/serviceRetnBD', component: "ServiceRentBd"
    },
    {
        id: 11, name: "BANK DETAILS", path: '/bankDetails', component: "BankDetails"
    },
    {
        id: 12, name: "JOINT ACCOUNT DETAILS", path: '/jointAccountDetails', component: "JointAccountDetails"
    },
    {
        id: 13, name: "BPET/PPT", path: '/bpetPpt', component: "BpetPpt"
    },
    {
        id: 14, name: "FIRING", path: '/firing', component: "Firing"
    },
    {
        id: 15, name: "HOSPITALIZATION DETAILS", path: '/hospitalizationDetails', component: "HospitalizationDeatils"
    },
    {
        id: 16, name: "INJURY PART II ORDER", path: '/injuryPart2Orders', component: "InjuryPart2"
    },
    {
        id: 17, name: "LVE DETAILS", path: '/lveDetails', component: "LveDetails"
    },
    {
        id: 18, name: "ACC LVE", path: '/accLve', component: "AccLve"
    },
    {
        id: 19, name: "MARITIAL DISCORD CASE", path: '/maritialDiscordCase', component: "MaritialDiscordCase"
    },
    {
        id: 20, name: "CIVIL EDN", path: '/civilEdn', component: "CivilEdn"
    },
    {
        id: 21, name: "MIL EDN", path: '/milEdn', component: "MilEdn"
    },
    {
        id: 22, name: "CANTEEN CARD", path: '/canteenCard', component: "CanteenCard"
    },
    {
        id: 23, name: "DEPENDENT CARD", path: '/dependentCard', component: "DependentCard"
    },
    {
        id: 24, name: "NOK DETAILS", path: '/nokDetails', component: "NokDetails"
    },
    {
        id: 25, name: "INTERVIEW DETAILS", path: '/interviewDetails', component: "InterviewDetails"
    },
    {
        id: 26, name: "FAMILY DETAILS", path: '/familyDetails', component: "FamilyDetails"
    }
]

export const familyRelationTypes = [
    { value: 'FATHER', name: "FATHER" },
    { value: 'MOTHER', name: "MOTHER" },
    { value: 'WIFE', name: "WIFE" },
    { value: 'SON', name: "SON" },
    { value: 'DAUGHTER', name: "DAUGHTER" },
    { value: 'SISTER', name: "SISTER" },
    { value: 'SIBILINGS', name: "SIBILINGS" },
]

export const milEdnTypes = [
    { value: '10th', name: '10th' },
    { value: '12th', name: '12th' },
    { value: 'MR-II', name: 'MR-II' },
    { value: 'MR-I', name: 'MR-I' },
]

export const civilEdnTypes = [
    { value: '8th', name: '8th' },
    { value: '10th', name: '10th' },
    { value: '12th', name: '12th' },
    { value: 'GRADUATION', name: 'GRADUATION' }
]

export const lveTypes = [
    { value: 'AL', name: 'AL' },
    { value: 'PAL', name: 'PAL' },
    { value: 'BAL', name: 'BAL' },
    { value: 'CL', name: 'CL' },
    { value: 'YEAR', name: 'YEAR' },
]

export const macpTypes = [
    { value: '1st', name: '1st' },
    { value: '2nd', name: '2nd' },
    { value: '3rd', name: '3rd' },
]

export const promotionDetailsTypes = [
    { value: 'LNK', name: 'LNK' },
    { value: 'NK', name: 'NK' },
    { value: 'LHAV', name: 'LHAV' },
    { value: 'HAV', name: 'HAV' },
    { value: 'NB SUB', name: 'NB SUB' },
    { value: 'SUB', name: 'SUB' },
    { value: 'HONY LT', name: 'HONY LT' },
    { value: 'HONY CAPT', name: 'HONY CAPT' },
]

export const loanTypes = [
    { value: 'PERSONAL', name: 'PERSONAL' },
    { value: 'HOME', name: 'HOME' },
    { value: 'CAR', name: 'CAR' },
]
export const bankAccountTypes = [
    { value: 'SAVINGS', name: 'SAVINGS' },
    { value: 'CURRENT', name: 'CURRENT' },
]
export const searchTypes = [
    { name: 'PERSONAL DETAILS', value: 'personalDetails' },
    { name: 'PUNISHMENT', value: 'punishment' },
    { name: 'COURSES', value : 'courses' },
    { name: 'CADRES', value : 'cadres' },
    { name: 'ERES', value: 'eres' },
    { name: 'LOAN DETAILS', value: 'loan' },
    { name: 'PROMOTION DETAILS', value: 'promotionDetails' },
    { name: 'AFPP', value: 'afpp' },
    { name: 'MACP', value: 'macp' },
    { name: 'SERVICE RETN BD', value: 'serviceRetnBd' },
    {name: 'BANK DETAILS', value : 'bank' },
    { name: 'JOINT ACCOUNT DETAILS', value: 'jointAccount' },
    { name: 'BPET/PPT', value: 'bpetPpt' },
    { name: 'FIRING', value: 'firing' },
    { name: 'HOSPITALIZATION DETAILS', value : 'hospitalization' },
    { name: 'INJURY PART II ORDER DETAILS', value: 'injury' },
    { name: 'LVE DETAILS', value: 'lveDetails' },
    { name: 'ACC LVE', value: 'accLve' },
    { name: 'MARTIAL DISCORD CASE', value: 'martialDiscordCase' },
    { name: 'CIVIL EDN', value: 'civilEdn' },
    { name: 'MIL EDN', value: 'milEdn' },
    { name: 'CANTEEN CARD', value: 'canteenCard' },
    { name: 'DEPENDENT CARD', value: 'dependentCard' },
    { name: 'NOK DETAILS', value: 'nok_details' },
    { name: 'INTERVIEW DETAILS', value: 'interview' },
    { name: 'FAMILY DETAILS',value : 'family' },
]
