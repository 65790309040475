import React, { useEffect, useState } from 'react'
import TopNav from '../../../Components/TopNav'
import { useNavigate } from 'react-router'
import SideNav from '../../../Components/SideNav'
import { familyRelationTypes, milEdnTypes } from '../../../Common/utilities'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../Common/Storage'
import { emp_family_details } from '../../../Common/Redux/Actions'
import API from '../../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../../Common/Toast'
import moment from 'moment'

export default function FamilyDetails() {

    const reduxFamilyDetails = useSelector((state) => state?.empReducer?.family_details)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (reduxFamilyDetails?.length > 0) {
            setData(reduxFamilyDetails)
        }
    }, [reduxFamilyDetails])

    useEffect(() => {
        if (getToken('empAddToken') !== null) {
            dispatch(emp_family_details(getToken('empAddToken')))
        }
    }, [getToken('empAddToken')])

    const [data, setData] = useState([{}]);

    const handleChange = (idx) => (event) => {
        const { name, type, value } = event.target
        if (type === 'file' && event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
            if (fileSizeInMB > 1) {
                toastWarning('File size exceeds the limit of 1MB. Please choose a smaller file.');
                const fileInput = document.getElementById(`imageFileInput${idx}`);
                fileInput.value = ''; // Reset the file input value
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64Image = e.target.result;
                    const updatedRows = [...data];
                    updatedRows[idx] = {
                        ...updatedRows[idx],
                        [name]: base64Image
                    };
                    setData(updatedRows);
                };
                reader.readAsDataURL(file);
            }
        } else {
            const updatedRows = [...data];
            updatedRows[idx] = {
                ...updatedRows[idx],
                [name]: value
            };
            setData(updatedRows);
        }
    };

    console.log(data)

    const handleAddRow = () => {
        const item = {
            relation_type: "",
            relation_name: "",
            dob: "",
            age: "",
            occupation: "",
            qualification: "",
            dimise: "",
            part2_order_no: "",
            image_url: "",
        };
        setData([...data, item]);
    };

    const handleRemoveRow = () => {
        setData(data.slice(0, -1));
    };

    const handleRemoveSpecificRow = (item_id) => () => {
        API.post(`/emp_block/delete_emp_family_details`, { item_id: item_id, emp_id: getToken('empAddToken') }, {}, true)
            .then(result => {
                toastSuccess(result.message)
                dispatch(emp_family_details(getToken('empAddToken')))
            })
            .catch(error => {
                console.log(error)
            })
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (reduxFamilyDetails?.length > 0) {
            API.post(`/emp_block/update_emp_family_details`, { data: data, emp_id: getToken('empAddToken') }, {}, true)
                .then(result => {
                    toastSuccess(result.message)
                    dispatch(emp_family_details(getToken('empAddToken')))
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            API.post(`/emp_block/emp_family_details`, { data: data, emp_id: getToken('empAddToken') }, {}, true)
                .then(result => {
                    toastSuccess(result.message)
                    dispatch(emp_family_details(getToken('empAddToken')))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div className='mb-5'>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">FAMILY DETAILS : {getToken('empName')}</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                              <div className="me-2">
                                    <button onClick={() => navigate('/searchEmployee')} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-search"></i> Search Employee</button>
                                </div>
                            </div>
                        </div>

                        <label></label>
                        <div className="table-responsive">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="col-md-12 column">
                                    <div style={{ float: 'right' }}>
                                        <button type="button" onClick={handleAddRow} className="btn btn-outline-primary btn-sm m-1">
                                            Add
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleRemoveRow}
                                            className="btn btn-outline-danger btn-sm m-1 float-right"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    <table className="rwd-table table table-bordered table-heading" id="tab_logic">
                                        <thead>
                                            <tr>
                                                <th className="text-center">SNO</th>
                                                <th className="text-center">RELATION TYPE</th>
                                                <th className="text-center">RELATION NAME</th>
                                                <th className="text-center">DATE OF BIRTH</th>
                                                <th className="text-center">AGE</th>
                                                <th className="text-center">OCCUPATION</th>
                                                <th className="text-center">QUALIFICATION</th>
                                                <th className="text-center">DEMISE</th>
                                                <th className="text-center">PART-II ORDER NO</th>
                                                <th className="text-center">IMAGE</th>
                                                <th className="text-center">ACTION</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td data-th="SNO">{idx + 1}</td>
                                                    <td data-th="RELATION TYPE" width={"200px"}>
                                                        <select
                                                            name="relation_type"
                                                            value={item.relation_type}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                            required
                                                        >
                                                            <option value="">Select One</option>
                                                            {
                                                                familyRelationTypes.map((item, index) => {
                                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                                })
                                                            }

                                                        </select>
                                                    </td>
                                                    <td data-th="REALTION NAME" width={"200px"} >
                                                        <input
                                                            type="text"
                                                            name="relation_name"
                                                            value={item.relation_name}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="DATE" width={"80px"}>
                                                        <input
                                                            type="date"
                                                            name="dob"
                                                            value={item.dob}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="AGE" width={"280px"}>
                                                        <input
                                                            type="number"
                                                            name="age"
                                                            value={item.age}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="OCCUPATION">
                                                        <input
                                                            type="text"
                                                            name="occupation"
                                                            value={item.occupation}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="QUALIFICATION">
                                                        <input
                                                            type="text"
                                                            name="qualification"
                                                            value={item.qualification}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="DEMISE">
                                                        <input
                                                            type="text"
                                                            name="dimise"
                                                            value={item.dimise}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="PART-II ORDER NO" style={{ width: '200px' }}>
                                                        <input
                                                            type="text"
                                                            name="part2_order_no"
                                                            value={item.part2_order_no}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="IMAGE" style={{ width: '50px' }}>
                                                        <div className='card' style={{ width: '100px', height: '100px' }}>
                                                            <img src={item?.image_url} width={100} height={100} />
                                                        </div>
                                                        <br />
                                                        <input
                                                            type="file"
                                                            name="image_url"
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="ACTION">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={handleRemoveSpecificRow(item.id)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <button type="submit" className='btn btn-sm btn-primary m-1'>{reduxFamilyDetails?.length > 0 ? "Update" : "Save"}</button>
                                    <button type="button" onClick={() => navigate('/dashboard')} className='btn btn-sm btn-primary m-1'>Close</button>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div >
        </div >
    )
}