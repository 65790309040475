import React, { useEffect, useState } from 'react'
import TopNav from '../../../Components/TopNav'
import { useNavigate } from 'react-router'
import SideNav from '../../../Components/SideNav'
import { useDispatch, useSelector } from 'react-redux'
import { getToken } from '../../../Common/Storage'
import { emp_afpp_details } from '../../../Common/Redux/Actions'
import API from '../../../Common/Network/API'
import { toastSuccess } from '../../../Common/Toast'
import moment from 'moment'

export default function AFPP() {

    const reduxAfppDetails = useSelector((state) => state?.empReducer?.afpp_details)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    console.log(reduxAfppDetails)

    useEffect(() => {
        if (reduxAfppDetails?.length > 0) {
            setData(reduxAfppDetails)
        }
    }, [reduxAfppDetails])

    useEffect(() => {
        if (getToken('empAddToken') !== null) {
            dispatch(emp_afpp_details(getToken('empAddToken')))
        }
    }, [getToken('empAddToken')])

    const [data, setData] = useState([{}]);

    const handleChange = (idx) => (e) => {
        const { name, value } = e.target;
        const updatedRows = [...data];
        updatedRows[idx] = {
            ...updatedRows[idx],
            [name]: value
        };
        setData(updatedRows);
    };

    const handleAddRow = () => {
        const item = {
            withdrawn_amount: "",
            year: "",
            remarks: ""
        };
        setData([...data, item]);
    };

    const handleRemoveRow = () => {
        setData(data.slice(0, -1));
    };

    const handleRemoveSpecificRow = (item_id) => () => {
        API.post(`/emp_block/delete_emp_afpp_details`, { item_id: item_id, emp_id: getToken('empAddToken') }, {}, true)
            .then(result => {
                toastSuccess(result.message)
                dispatch(emp_afpp_details(getToken('empAddToken')))
            })
            .catch(error => {
                console.log(error)
            })
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (reduxAfppDetails?.length > 0) {
            API.post(`/emp_block/update_emp_afpp_details`, { data: data, emp_id: getToken('empAddToken') }, {}, true)
                .then(result => {
                    toastSuccess(result.message)
                    dispatch(emp_afpp_details(getToken('empAddToken')))
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            API.post(`/emp_block/emp_afpp_details`, { data: data, emp_id: getToken('empAddToken') }, {}, true)
                .then(result => {
                    toastSuccess(result.message)
                    dispatch(emp_afpp_details(getToken('empAddToken')))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div className='mb-5'>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">AFPP DETAILS : {getToken('empName')}</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                              <div className="me-2">
                                    <button onClick={() => navigate('/searchEmployee')} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-search"></i> Search Employee</button>
                                </div>
                            </div>
                        </div>

                        <label></label>
                        <div className="table-responsive">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="col-md-12 column">
                                    <div style={{ float: 'right' }}>
                                        <button type="button" onClick={handleAddRow} className="btn btn-outline-primary btn-sm m-1">
                                            Add
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleRemoveRow}
                                            className="btn btn-outline-danger btn-sm m-1 float-right"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                    <table className="rwd-table table table-bordered table-heading" id="tab_logic">
                                        <thead>
                                            <tr>
                                                <th className="text-center">SNO</th>
                                                <th className="text-center">TOTAL AMT WITHDRAWL</th>
                                                <th className="text-center">YEAR</th>
                                                <th className="text-center">REMARKS</th>
                                                <th className="text-center">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, idx) => (
                                                <tr id="addr0" key={idx}>
                                                    <td data-th="SNO">{idx + 1}</td>
                                                    <td data-th="TOTAL AMT WITHDRAWN" width={"120px"}>
                                                        <input
                                                            type="text"
                                                            name="withdrawn_amount"
                                                            value={item.withdrawn_amount}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />

                                                    </td>
                                                    <td data-th="YEAR">
                                                        <input
                                                            type="date"
                                                            name="year"
                                                            value={item.year !== undefined ? moment(item.year).format('YYYY-MM-DD') : ""}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="REMARKS">
                                                        <input
                                                            type="text"
                                                            name="remarks"
                                                            value={item.remarks}
                                                            onChange={handleChange(idx)}
                                                            className="form-control"
                                                        />
                                                    </td>
                                                    <td data-th="ACTION">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-danger btn-sm"
                                                            onClick={handleRemoveSpecificRow(item.id)}
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <button type="submit" className='btn btn-sm btn-primary m-1'>{reduxAfppDetails?.length > 0 ? "Update" : "Save"}</button>
                                    <button type="button" onClick={() => navigate('/macp')} className='btn btn-sm btn-primary m-1'>Next</button>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div >
        </div >
    )
}