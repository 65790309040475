import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { menuArray } from "../../Common/utilities";
import { Link } from 'react-router-dom'


export default function SideNav() {

    const loginUserData = useSelector((state) => state?.authReducer?.auth?.data?.data)
    const [activeClass, setActiveClass] = useState()

    const handleClick = (type) => {
        setActiveClass(type)
    }

    useEffect(() => {
        setActiveClass(window.location.pathname)
    }, [])

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-body-tertiary sidebar collapse">
            <div className="position-sticky pt-3 sidebar-sticky">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link className={activeClass === '/dashboard' ? "nav-link active" : "nav-link"} aria-current="page" to="/dashboard" onClick={() => {
                            handleClick('/dashboard')
                        }}>
                            <span data-feather="home" className="align-text-bottom"></span>
                            DASHBOARD
                        </Link>
                    </li>
                    {
                        loginUserData?.role === 'user' && window.location.pathname !== '/dashboard' && window.location.pathname !== '/viewEmployee' && window.location.pathname !== '/searchEmployee' &&
                        <>
                            {
                                menuArray.map((item, index) => {
                                    return (
                                        <li key={index} className="nav-item collapsed">
                                            <Link onClick={() => {
                                                handleClick(item.path)
                                            }} className={activeClass === item.path ? "nav-link active" : "nav-link"} style={{ lineHeight: '3px' }} aria-current="page" to={`${item.path}`}>
                                                <span data-feather="file" className="align-text-bottom" ></span>
                                                {item.name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </>
                    }
                </ul>

                {/* <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-body-secondary text-uppercase">
                    <span>Saved reports</span>
                    <a className="link-secondary" href="#" aria-label="Add a new report">
                        <span data-feather="plus-circle" className="align-text-bottom"></span>
                    </a>
                </h6> */}
                {/* <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <span data-feather="file-text" className="align-text-bottom"></span>
                            Current month
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">
                            <span data-feather="file-text" className="align-text-bottom"></span>
                            Last quarter
                        </a>
                    </li>
                </ul> */}
            </div>
        </nav >
    )
}