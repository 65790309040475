import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { getToken, removeToken } from "../../Common/Storage";
import { userInfo } from "../../Common/Redux/Actions";
import { useNavigate } from "react-router-dom";
import { BUILD_DATE, BUILD_VERSION, TASK_URL } from "../../config";
import { toastSuccess } from "../../Common/Toast";
import Modal from 'react-modal';
export default function TopNav() {

    const reduxState = useSelector((state) => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [supportView, setSupportView] = useState(false)
    const [supportData, setSupportData] = useState()

    useEffect(() => {
        if (getToken('auth') !== null) {
            let decoded = jwt_decode(getToken('auth'));
            let data = {
                data: decoded,
                token: getToken('auth')
            };
            dispatch(userInfo(data))
        } else {
            handleLogout()
        }
    }, [])

    const handleLogout = () => {
        removeToken('auth')
        removeToken('empAddToken')
        navigate('/')
    }

    const handleChange = (e) => {
        setSupportData({
            ...supportData,
            [e.target.name]: e.target.value
        })
    }

    const createSupportTicket = async (e) => {
        e.preventDefault()
        fetch(
            `${TASK_URL}/manage_userstories.php`,
            {
                method: 'POST',
                body: JSON.stringify({
                    action: "addMainTaskFromOthers",
                    title: supportData.title,
                    description: supportData.description,
                    projectId: '6fbc8968a4009ec5e004d6ee712e050c'
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }
        ).then((response) => response.json())
            .then((result) => {
                toastSuccess(result.message)
                setSupportView(false)
            })
            .catch((error) => {
                console.error('Error:', error);
                setSupportView(false)
            });
    }

    return (

        <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="/dashboard"><img src="./images/emp_logo.png" alt="" width="180" height="40" /></a>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            {/* <input className="form-control form-control-dark w-100 rounded-0 border-0" type="text" placeholder="Search" aria-label="Search" /> */}
            <div className="navbar-nav">
                <div className="nav-item text-nowrap">
                    <ul className="nav">
                        <li className="nav-item">
                            <label className="nav-link px-3 text-white" > V {BUILD_VERSION} {BUILD_DATE}</label>
                        </li>
                        <li className="nav-item">
                            <label className="nav-link px-3 text-white" style={{ cursor: 'pointer' }} onClick={() => setSupportView(true)} ><i class="bi bi-headset"></i> Support</label>
                        </li>
                        <li className="nav-item">
                            <label className="nav-link px-3 text-white" ><i className="bi bi-person"></i> {reduxState?.authReducer?.auth?.data?.data?.email}</label>
                        </li>
                        <li className="nav-item">
                            <label className="nav-link px-3 text-white" style={{ cursor: 'pointer' }} onClick={() => handleLogout()} ><i className="bi bi-box-arrow-right"></i> Sign out</label>
                        </li>
                    </ul>
                </div>
            </div>
            <Modal
                isOpen={supportView}
                onRequestClose={() => {
                    setSupportView(false)
                }}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
            >
                <div className='container'>
                    <center className='mt-2 mb-3'>
                        <h5>HOW CAN WE HELP YOU?</h5>
                    </center>
                    <form onSubmit={(e) => createSupportTicket(e)}>
                        <div className='container'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h5>Tell us about the issue you are facing!</h5>
                                </div>
                                <div className='card-body'>
                                    <div className='form-group'>
                                        <label>Could You Please Describe This Issue In One Sentence?</label>
                                        <textarea name="title" col={4} className='form-control' onChange={(e) => handleChange(e)} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Let Us Know How To Reproduce The Issue For Us To Work On?</label>
                                        <textarea name='description' col={4} className='form-control' onChange={(e) => handleChange(e)} required />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <center className='mt-2'>
                            <button style={{ marginRight: '10px' }} type="submit" className="btn btn-primary btn-sm" onClick={() => {
                            }}>Send</button>
                            <button type="button" className="btn btn-danger btn-sm" onClick={() => {
                                setSupportView(false)
                            }}>close</button>
                            <br />
                        </center>
                    </form>
                </div>
            </Modal>
        </header>

    )
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        height: 'auto',
        fontSize: '11px'
    },
    overlay: { zIndex: 9999999999 }
};