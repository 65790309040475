import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { removeToken, setToken } from '../../Common/Storage';
import API from '../../Common/Network/API';
import Pagination from "react-js-pagination";
import Loader from '../../Components/Loader';
import ViewEmployee from '../../Screens/Employee/View';

export default function CandidateTable(props) {
    const navigate = useNavigate()
    const [employeeData, setEmployeeData] = useState([]);
    const [isGridView, setIsGridView] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [entriesPerPage, setEntriesPerPage] = useState(5);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [loader, setLoader] = useState(false)
    const [empView, setEmpView] = useState(false)
    const [nameSearch, setNameSearch] = useState('');
    const [rankSearch, setRankSearch] = useState('');
    const [tradeSearch, setTradeSearch] = useState('');
    const [locationSearch, setLocationSearch] = useState('');
    const [mobileSearch, setMobileSearch] = useState('');



    useEffect(() => {
        setEmployeeData(props.employeeData)
        removeToken('empViewToken');
    }, [props.employeeData]);

    const totalPages = Math.ceil(employeeData.length / entriesPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleEntriesPerPageChange = (event) => {
        const value = parseInt(event.target.value, 10);
        setEntriesPerPage(value);
        setCurrentPage(1);
    };



    const toggleGridView = () => {
        setIsGridView(!isGridView);
    };

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
        }
    };

    const sortedData = [...employeeData]
        .filter((item) => {

            const nameMatch = item.name.toLowerCase().includes(nameSearch.toLowerCase());
            const rankMatch = item.rank.toLowerCase().includes(rankSearch.toLowerCase());
            const tradeMatch = item.trade.toLowerCase().includes(tradeSearch.toLowerCase());
            const locationMatch =
                locationSearch ? (
                    item.address &&
                    (item.address.toLowerCase().includes(locationSearch.toLowerCase()))
                ) : true;


            const mobileMatch = mobileSearch ? item.mobile.includes(mobileSearch) : true;
            return nameMatch && rankMatch && tradeMatch && locationMatch && mobileMatch;
        })

        .sort((a, b) => {
            if (sortField) {
                const valueA = a[sortField];
                const valueB = b[sortField];
                if (valueA < valueB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }
            return 0;
        });

    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    const displayedData = sortedData.slice(startIndex, endIndex);

    useEffect(() =>{
            setEmpView(false)
    },[props.showEmpView])

    return (
        <div>
            {loader && <Loader />}
            <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4">
                {
                    empView?
                        <div className='mt-4 mb-1'>
                            <ViewEmployee backButton={() => setEmpView(false)} />
                        </div>
                        :
                        employeeData.length > 0 ?
                            <div className='mt-4 mb-1'>
                                <div className='container'>
                                    <label>No of Rows</label>
                                    <div>
                                        <div style={{ marginRight: '1%' }} className="btn-group" role="group" aria-label="Basic example">
                                            <select
                                                className="form-select form-control-sm"
                                                value={entriesPerPage}
                                                onChange={handleEntriesPerPageChange}
                                            >
                                                {[5, 10, 30, 40, 50].map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                                {employeeData.length > 20 && (
                                                    <option value={employeeData.length}>All</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button
                                                type="button"
                                                className={!isGridView ? 'btn btn-light active' : 'btn btn-light'}
                                                onClick={toggleGridView}
                                            >
                                                <i className="bi bi-table"></i> Table View
                                            </button>
                                            <button
                                                type="button"
                                                className={isGridView ? 'btn btn-light active' : 'btn btn-light'}
                                                onClick={toggleGridView}
                                            >
                                                <i className="bi bi-grid"></i> Grid View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {isGridView ? (
                                    <div className="m-3">
                                        {displayedData.map((item, index) => {
                                            const tableIndex = startIndex + index + 1;
                                            return (
                                                <div key={tableIndex} className="row">
                                                    <div className="mb-1 mt-1">
                                                        <div className="card">
                                                            <div className="card-body d-flex">
                                                                <img
                                                                    src={item.image_url}
                                                                    className="card-img"
                                                                    alt="Employee"
                                                                    style={{ width: '70px', height: '70px', borderRadius: '50%', marginRight: '20px', backgroundColor: '#2a9df4' }}
                                                                />
                                                                <div className="ml-5">
                                                                    <h5 className="card-title" style={{ cursor: 'pointer' }} onClick={() => {
                                                                        setToken('empViewToken', item.emp_id);
                                                                        setEmpView(true)
                                                                    }}>
                                                                        {item.name}
                                                                    </h5>
                                                                    <label className="card-text"><b> {item.trade}</b> | <b>Rank:</b>{item.rank}</label>
                                                                    <br />
                                                                    <label className="card-text"> <b>Mobile No:</b>{item.mobile},{" "}<b>Address:</b>{item.address}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div>
                                        <div className="table-responsive m-3">
                                            <table className="table th table-hover">
                                                <thead>
                                                    <tr>
                                                        {sortOptions.map((option) => (
                                                            <th scope="col" key={option.value}>
                                                                <div>
                                                                    <button
                                                                        className={`dropdown-item ${sortField === option.value ? 'active' : ''}`}
                                                                        onClick={() => handleSort(option.value)}
                                                                    >
                                                                        {option.label}{' '}
                                                                        {sortField === option.value ? (
                                                                            <Sortcaret order={sortOrder} />
                                                                        ) : (
                                                                            <i className="bi bi-caret-down"></i>
                                                                        )}
                                                                    </button>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        placeholder={`Search ${option.label}`}
                                                                        value={
                                                                            option.value === 'name'
                                                                                ? nameSearch
                                                                                : option.value === 'rank'
                                                                                    ? rankSearch
                                                                                    : option.value === 'trade'
                                                                                        ? tradeSearch
                                                                                        : option.value === 'address'
                                                                                            ? locationSearch
                                                                                            : option.value === 'mobile'
                                                                                                ? mobileSearch
                                                                                                : ''
                                                                        }
                                                                        onChange={(e) => {
                                                                            if (option.value === 'name') {
                                                                                setNameSearch(e.target.value);
                                                                            } else if (option.value === 'rank') {
                                                                                setRankSearch(e.target.value);
                                                                            } else if (option.value === 'trade') {
                                                                                setTradeSearch(e.target.value);
                                                                            } else if (option.value === 'address') {
                                                                                setLocationSearch(e.target.value);
                                                                            } else if (option.value === 'mobile') {
                                                                                setMobileSearch(e.target.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>

                                                            </th>
                                                        ))}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {displayedData.map((item, index) => {
                                                        const tableIndex = startIndex + index + 1;
                                                        return (
                                                            <tr key={index}>
                                                                <th scope="row" style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setToken('empViewToken', item.emp_id);
                                                                    setEmpView(true)
                                                                }}>{item.name}</th>
                                                                <td>{item.rank}</td>
                                                                <td>{item.trade}</td>
                                                                <td>{item.address}</td>
                                                                <td>{item.mobile}</td>
                                                                <td data-th="Action">
                                                                    <button
                                                                        onClick={() => {
                                                                            setToken('empAddToken', item.emp_id);
                                                                            navigate('/personalDetails');
                                                                        }}
                                                                        className="btn btn-sm btn-outline-primary"
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {employeeData.length > entriesPerPage && (
                                    <div className="pagination-container">
                                        <Pagination
                                            activePage={currentPage}
                                            itemsCountPerPage={entriesPerPage}
                                            totalItemsCount={employeeData.length}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            innerClass="pagination justify-content-center"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            hideFirstLastPages={true}
                                        />
                                    </div>
                                )}
                            </div>
                            :
                            <div className='container'>
                                <div style={{  textAlign: 'center',}}>
                                    <img style={{ width: '600px' }} alt="No Data Found" src="./images/nodata.jpg" />
                                </div>
                            </div>

                }
            </main>
        </div>
    );
}

const Sortcaret = ({ order }) => {
    if (!order) {
        return <><i className="bi bi-caret-down"></i></>
    }

    if (order === 'desc') {
        return <i className="bi bi-caret-down"></i>;
    }

};

const sortOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Rank', value: 'rank' },
    { label: 'Trade', value: 'trade' },
    { label: 'Location', value: 'address' },
    { label: 'Mobile', value: 'mobile' },
    { label: 'Action', value: 'action' },
];
