import React, { useEffect, useState } from 'react'
import TopNav from '../../Components/TopNav'
import SideNav from '../../Components/SideNav'
import ReactModal from '../../Components/Modal'
import API from '../../Common/Network/API'
import { getDate } from '../../Common/date'
import { useSelector } from 'react-redux'
import { toastSuccess, toastWarning } from '../../Common/Toast'

const AddSiteUser = () => {

    const site_id = useSelector((state) => state.authReducer.auth.data.data.site_id)

    const [data, setData] = useState()
    const [accessLevels, setAccessLevels] = useState([])
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    useEffect(() => {
        getAccessLevel()
    }, [])

    const getAccessLevel = () => {
        API.get(`/site_block/get_access_level`, true)
            .then(res => {
                setAccessLevels(res.data)
            }).catch(err => {
                toastWarning(err.message)
            })
    }

    const handleCreateSiteUser = (e) => {
        e.preventDefault()
        API.post(`/site_block/site_user_create`, { data: data, site_id: site_id }, {}, true).then(res => {
            toastSuccess(res.message)
        }).catch(err => {
            toastWarning(err.message)
        })
    }

    return (
        <div>
            <form onSubmit={(e) => handleCreateSiteUser(e)}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username*</label>
                    <input name="username" type="text" className="form-control form-control-sm" id="username" placeholder="Username" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">First Name*</label>
                    <input name="first_name" type="text" className="form-control form-control-sm" id="first_name" placeholder="First Name" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">Last Name*</label>
                    <input name="last_name" type="text" className="form-control form-control-sm" id="last_name" placeholder="First Name" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="Input2" className="form-label">Email address*</label>
                    <input name="email" type="email" className="form-control form-control-sm" id="Input2" placeholder="name@example.com" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">Mobile*</label>
                    <input name="mobile" type="text" className="form-control form-control-sm" id="mobile" placeholder="" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="Input3" className="form-label">Password*</label>
                    <input name="password" type="password" className="form-control form-control-sm" id="Input3" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="access_level" className="form-label">Password*</label>
                    <select name="access_level" className="form-control form-control-sm" id="access_level" onChange={(e) => handleChange(e)} required>
                        <option value="">Select One</option>
                        {
                            accessLevels?.map((item, index) => {
                                return <option key={index} value={item.access_level_id}>{item.short_description}</option>
                            })
                        }
                    </select>
                </div>
                <div className='mb-3'>
                    <button type="submit" className='btn btn-primary btn-sm m-1'>Add</button>
                    <button type="button" onClick={() => console.log(false)} className='btn btn-danger btn-sm m-1'>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default function AdminDashboard() {

    const [openModal, setOpenModal] = useState(false)
    const [siteUsers, setSiteUsers] = useState([])

    const addRootAdmin = () => {
        setOpenModal(true)
    }

    useEffect(() => {
        getSiteUsers()
        setOpenModal(false)
    }, [])

    const getSiteUsers = () => {
        API.get(`/site_block/get_site_users`, true)
            .then(result => {
                setSiteUsers(result.data)
            })
    }
    return (
        <div>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">Dashboard</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="btn-group me-2">
                                    <button onClick={() => addRootAdmin()} type="button" className="btn btn-sm btn-outline-primary"><i className="bi bi-plus-circle"></i> Add User</button>
                                </div>
                            </div>
                        </div>

                        <label>Users</label>
                        <div className="table-responsive">
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">First Name</th>
                                        <th scope="col">Last Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Access Level</th>
                                        <th scope="col">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        siteUsers.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.user_name}</td>
                                                    <td>{item.first_name}</td>
                                                    <td>{item.last_name}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{item.short_description}</td>
                                                    <td>{getDate(item.created_at)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </main>
                    <ReactModal isOpen={openModal} closeModal={(data) => setOpenModal(data)} modalComponent={<AddSiteUser />} />
                </div>
            </div >
        </div >
    )
}