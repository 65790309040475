import React, { useEffect, useState } from 'react'
import TopNav from '../../Components/TopNav'
import SideNav from '../../Components/SideNav'
import ReactModal from '../../Components/Modal'
import API from '../../Common/Network/API'
import { getDate } from '../../Common/date'

const AddSuperAdmin = () => {

    const [data, setData] = useState()
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleCreateSuperAdmin = (e) => {
        e.preventDefault()
        API.post(`/login_block/super_admin_create`, data, {}, false).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div>
            <form onSubmit={(e) => handleCreateSuperAdmin(e)}>
                <div className="mb-3">
                    <label htmlFor="Input1" className="form-label">Site Name*</label>
                    <input name="site_name" type="text" className="form-control" id="Input1" placeholder="Site name" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="Input2" className="form-label">Email Address*</label>
                    <input name="email" type="email" className="form-control" id="Input2" placeholder="name@example.com" onChange={(e) => handleChange(e)} required />
                </div>
                <div className="mb-3">
                    <label htmlFor="Input3" className="form-label">Password*</label>
                    <input name="password" type="password" className="form-control" id="Input3" onChange={(e) => handleChange(e)} required />
                </div>
                <div className='mb-3'>
                    <button type="submit" className='btn btn-primary btn-sm m-1'>Add</button>
                    <button type="button" onClick={() => console.log(false)} className='btn btn-danger btn-sm m-1'>Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default function RootDashboard() {

    const [openModal, setOpenModal] = useState(false)
    const [siteUsers, setSiteUsers] = useState([])

    const addRootAdmin = () => {
        setOpenModal(true)
    }

    useEffect(() => {
        getRootUsers()
    },[])

    const getRootUsers = () => {
        API.get(`/root_block/getSiteUsers`, false)
            .then(result => {
                setSiteUsers(result.data)
            })
    }
    return (
        <div>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">Dashboard</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="btn-group me-2">
                                    <button onClick={() => addRootAdmin()} type="button" className="btn btn-sm btn-outline-primary"><i className="bi bi-plus-circle"></i> Add Super Admin</button>
                                </div>
                            </div>
                        </div>

                        <label>Super Admins</label>
                        <div className="table-responsive">
                            <table className="table table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Site Name</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        siteUsers.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.site_name}</td>
                                                    <td>Super Admin</td>
                                                    <td>{item.email}</td>
                                                    <td>{getDate(item.created_at)}</td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </main>
                    <ReactModal isOpen={openModal} closeModal={(data) => setOpenModal(data)} modalComponent={<AddSuperAdmin />} />
                </div>
            </div >
        </div >
    )
}