import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import AdminLogin from "./Screens/Login/adminLogin";
import RootDashboard from "./Screens/Dashboard/rootDashboard";
import './App.css'
import RootLogin from "./Screens/Login/rootLogin";
import { ToastContainer, toast } from 'react-toastify';
import AdminDashboard from "./Screens/Dashboard/adminDashboard";
import UserLogin from "./Screens/Login/userLogin";
import Dashboard from "./Screens/Dashboard/dashboard";
import AddEmployee from "./Screens/Employee/Add";
import SearchEmployee from "./Screens/Employee/Search";
import PersonalDetails from "./Screens/Employee/Add/personalDetails";

import Punishment from "./Screens/Employee/Add/punishment";
import Cadres from "./Screens/Employee/Add/cadres";
import Courses from "./Screens/Employee/Add/courses";
import ERES from "./Screens/Employee/Add/eres";
import LoanDetails from "./Screens/Employee/Add/loanDetails";
import PromotionDetails from "./Screens/Employee/Add/promotionDetails";
import AFPP from "./Screens/Employee/Add/afpp";
import MACP from "./Screens/Employee/Add/macp";
import ServiceRetnBD from "./Screens/Employee/Add/serviceRetnBD";
import BankDetails from "./Screens/Employee/Add/bankDetails";
import JointAccountDetails from "./Screens/Employee/Add/jointAccountDetails";
import BPETPPT from "./Screens/Employee/Add/bpetPpt";
import Firing from "./Screens/Employee/Add/firing";
import HospitalizationDetails from "./Screens/Employee/Add/hospitalizationDetails";
import InjuryPart2Orders from "./Screens/Employee/Add/injuryPart2Order";
import LVE from "./Screens/Employee/Add/lve";
import ACCLVE from "./Screens/Employee/Add/accLve";
import MaritialDiscordCase from "./Screens/Employee/Add/maritialDiscordCase";
import CivilEdn from "./Screens/Employee/Add/civilEdn";
import MilEdn from "./Screens/Employee/Add/milEdn";
import CanteenCard from "./Screens/Employee/Add/canteenCard";
import DependentCard from "./Screens/Employee/Add/dependentCard";
import NokDetails from "./Screens/Employee/Add/nokDetails";
import FamilyDetails from "./Screens/Employee/Add/familyDetails";
import InterviewDetails from "./Screens/Employee/Add/interviewDetails";
import ViewEmployee from "./Screens/Employee/View";
import ForgotPassword from "./Screens/Login/forgotPassword";
import OtpInput from "./Screens/Login/otpInput";
import ResetPassword from "./Screens/Login/resetPassword";
import { useEffect } from "react";
import API from "./Common/Network/API";
import { BUILD_VERSION } from "./config";


function App() {

  const CustomToast = ({ message }) => (
    <div>
      {message}{' '}
      <a href="/" rel="noopener noreferrer">
        Click here
      </a>
    </div>
  );

  const getVersion = (req, res) => {
    API.get(`/check_version`, true)
      .then(res => {
        if (res.version !== BUILD_VERSION){
          toast.info(<CustomToast message="New Version Available!" />, {
            position: toast.POSITION.BOTTOM_RIGHT,
        });
        }
      })
  }

  useEffect(() => {
    // Function to be executed every 10 minutes
    getVersion()
    let waitTime = 10 * 60 * 1000;
    const intervalId = setInterval(getVersion, waitTime);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path="/rootLogin" element={<RootLogin />} />
        <Route path="/rootDashboard" element={<RootDashboard />} />
        <Route path="/adminLogin" element={<AdminLogin />} />
        <Route path="/adminDashboard" element={<AdminDashboard />} />
        <Route path="/" element={<UserLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/addEmployee" element={<AddEmployee />} />
        <Route path="/searchEmployee" element={<SearchEmployee />} />
        <Route path="/personalDetails" element={<PersonalDetails />} />
        <Route path="/punishment" element={<Punishment />} />
        <Route path="/cadres" element={<Cadres />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/eres" element={<ERES />} />
        <Route path="/loanDetails" element={<LoanDetails />} />
        <Route path="/promotionDetails" element={<PromotionDetails />} />
        <Route path="/afpp" element={<AFPP />} />
        <Route path="/macp" element={<MACP />} />
        <Route path="/serviceRetnBD" element={<ServiceRetnBD />} />
        <Route path="/bankDetails" element={<BankDetails />} />
        <Route path="/jointAccountDetails" element={<JointAccountDetails />} />
        <Route path="/bpetPpt" element={<BPETPPT />} />
        <Route path="/firing" element={<Firing />} />
        <Route path="/hospitalizationDetails" element={<HospitalizationDetails />} />
        <Route path="/injuryPart2Orders" element={<InjuryPart2Orders />} />
        <Route path="/lveDetails" element={<LVE />} />
        <Route path="/accLve" element={<ACCLVE />} />
        <Route path="/maritialDiscordCase" element={<MaritialDiscordCase />} />
        <Route path="/civilEdn" element={<CivilEdn />} />
        <Route path="/milEdn" element={<MilEdn />} />
        <Route path="/canteenCard" element={<CanteenCard />} />
        <Route path="/dependentCard" element={<DependentCard />} />
        <Route path="/nokDetails" element={<NokDetails />} />
        <Route path="/interviewDetails" element={<InterviewDetails />} />
        <Route path="/familyDetails" element={<FamilyDetails />} />
        <Route path="/viewEmployee" element={<ViewEmployee />} />
        <Route path="/userForgotPassword" element={<ForgotPassword />} />
        <Route path="/userOtpInput" element={<OtpInput />} />
        <Route path="/userResetPassword" element={<ResetPassword />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
