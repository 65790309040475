import { configureStore } from '@reduxjs/toolkit'
import { user_reducer, emp_reducer } from './Reducers'


// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
        authReducer: user_reducer,
        empReducer: emp_reducer
    }
})