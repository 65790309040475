import React, { useState } from 'react';

const ValidationPage = ({ value, onChange, name, label, required }) => {
  const [error, setError] = useState('');

  const validateInput = () => {
     if (value !== undefined) {
      if (name === 'mobile') {
        const mobileRegex = /^[0-9]{10}$/;
        if (!value.match(mobileRegex)) {
          setError('Invalid mobile number. Please enter a 10-digit number.');
        } else {
          setError('');
        }
      }else if (name === 'aadhar_card') {
        const aadharRegex = /^\d{4}\s\d{4}\s\d{4}$/; // Regular expression for Aadhar number with spaces
        if (!value.match(aadharRegex)) {
          setError('Invalid Aadhar number. Please enter a valid 12-digit Aadhar number with spaces as same as in Aadhar.');
        } else {
          setError('');
        }
      } 
       else if (name === 'email') {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!value.match(emailRegex)) {
          setError('Invalid email address. Please enter a valid email.');
        } else {
          setError('');
        }
      } else if (name === 'pan_card') {
        const panCardRegex = /^[A-Z0-9]{10}$/;
        if (!value.match(panCardRegex)) {
          setError('Invalid PAN Card number. Please enter a valid 10-character PAN Card number.');
        } else {
          setError('');
        }
      }
    } else {
      setError('');
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    let updatedValue = inputValue;

    if (name === 'mobile') {
      let truncatedValue = inputValue.slice(0, 10); 
      updatedValue = truncatedValue.replace(/\D/g, '');
    }
    else if (name === 'pan_card') {
      updatedValue = inputValue.slice(0, 10); 
      updatedValue = updatedValue.toUpperCase();
    }
    if (name === 'aadhar_card') {
      updatedValue = inputValue.replace(/[^0-9\s]/g, '');
      updatedValue = updatedValue.replace(/\s+/g, ' ');
      if (updatedValue.length - updatedValue.replace(/\s/g, '').length > 2) {
        updatedValue = updatedValue.replace(/\s/g, '');
      }
    }


    onChange({ target: { name, value: updatedValue } });
  };

  return (
    <div>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <input
        id={name}
        value={value}
        className="form-control form-control-sm"
        name={name}
        type="text"
        onChange={handleInputChange}
        onBlur={validateInput}
        required={required}
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default ValidationPage;
