import { EMP_PERSONAL_DETAILS, USER_INFO, EMP_PUNISHMENT_DETAILS, EMP_COURSE_DETAILS, EMP_CADRES_DETAILS, EMP_ERES_DETAILS, EMP_PERSONAL_LOAN_DETAILS, EMP_PROMOTION_DETAILS, EMP_AFPP_DETAILS, EMP_MACP_DETAILS, EMP_SERVICE_RETN_BD_DETAILS, EMP_BANK_DETAILS, EMP_JOINT_ACCOUNT_DETAILS, EMP_BPET_PPT_DETAILS, EMP_FIRING_DETAILS, EMP_HOSPITALIZATION_DETAILS, EMP_INJURY_PART2_ORDER_DETAILS, EMP_LVE_DETAILS, EMP_ACC_LVE_DETAILS, EMP_MARITIAL_DISCORD_CASE_DETAILS, EMP_CIVIL_EDN_DETAILS, EMP_MIL_EDN_DETAILS, EMP_CANTEEN_CARD_DETAILS, EMP_DEPENDENT_CARD_DETAILS, EMP_NOK_DETAILS, EMP_INTERVIEW_DETAILS, EMP_FAMILY_DETAILS } from '../Actions/index'

let intialState = {
    auth: null,
    isAuthenticated: false
}

export const emp_details = {
    peronal_details: null,
    punishment_details: [],
    course_details: [],
    cadres_details: [],
    eres_details: [],
    personal_loan_details: [],
    promotion_details: [],
    afpp_details: [],
    macp_details: [],
    service_retn_bd_details: [],
    bank_details: [],
    joint_account_details: [],
    bpet_ppt_details: [],
    firing_details: [],
    hospitalization_details: [],
    injury_part2_order_details: [],
    lve_details: [],
    acc_lve_details: [],
    maritial_discord_case_details: [],
    civil_edn_details: [],
    mil_edn_details: [],
    canteen_card_details: [],
    dependent_card_details: [],
    nok_details: [],
    interview_details: [],
    family_details: []
}

export const user_reducer = (state = intialState, action) => {
    switch (action.type) {
        case USER_INFO:
            return {
                ...state,
                auth: action.payload,
                isAuthenticated: true
            }
        default:
            return state
    }
}

export const emp_reducer = (state = emp_details, action) => {
    switch (action.type) {
        case EMP_PERSONAL_DETAILS:
            return {
                ...state,
                personal_details: action.payload
            }
        case EMP_PUNISHMENT_DETAILS:
            return {
                ...state,
                punishment_details: action.payload
            }
        case EMP_COURSE_DETAILS:
            return {
                ...state,
                course_details: action.payload
            }
        case EMP_CADRES_DETAILS:
            return {
                ...state,
                cadres_details: action.payload
            }
        case EMP_ERES_DETAILS:
            return {
                ...state,
                eres_details: action.payload
            }
        case EMP_PERSONAL_LOAN_DETAILS:
            return {
                ...state,
                personal_loan_details: action.payload
            }
        case EMP_PROMOTION_DETAILS:
            return {
                ...state,
                promotion_details: action.payload
            }
        case EMP_AFPP_DETAILS:
            return {
                ...state,
                afpp_details: action.payload
            }
        case EMP_MACP_DETAILS:
            return {
                ...state,
                macp_details: action.payload
            }
        case EMP_SERVICE_RETN_BD_DETAILS:
            return {
                ...state,
                service_retn_bd_details: action.payload
            }
        case EMP_BANK_DETAILS:
            return {
                ...state,
                bank_details: action.payload
            }
        case EMP_JOINT_ACCOUNT_DETAILS:
            return {
                ...state,
                joint_account_details: action.payload
            }
        case EMP_BPET_PPT_DETAILS:
            return {
                ...state,
                bpet_ppt_details: action.payload
            }
        case EMP_FIRING_DETAILS:
            return {
                ...state,
                firing_details: action.payload
            }
        case EMP_HOSPITALIZATION_DETAILS:
            return {
                ...state,
                hospitalization_details: action.payload
            }
        case EMP_INJURY_PART2_ORDER_DETAILS:
            return {
                ...state,
                injury_part2_order_details: action.payload
            }
        case EMP_LVE_DETAILS:
            return {
                ...state,
                lve_details: action.payload
            }
        case EMP_ACC_LVE_DETAILS:
            return {
                ...state,
                acc_lve_details: action.payload
            }
        case EMP_MARITIAL_DISCORD_CASE_DETAILS:
            return {
                ...state,
                maritial_discord_case_details: action.payload
            }
        case EMP_CIVIL_EDN_DETAILS:
            return {
                ...state,
                civil_edn_details: action.payload
            }
        case EMP_MIL_EDN_DETAILS:
            return {
                ...state,
                mil_edn_details: action.payload
            }
        case EMP_CANTEEN_CARD_DETAILS:
            return {
                ...state,
                canteen_card_details: action.payload
            }
        case EMP_DEPENDENT_CARD_DETAILS:
            return {
                ...state,
                dependent_card_details: action.payload
            }
        case EMP_NOK_DETAILS:
            return {
                ...state,
                nok_details: action.payload
            }
        case EMP_INTERVIEW_DETAILS:
            return {
                ...state,
                interview_details: action.payload
            }
        case EMP_FAMILY_DETAILS:
            return {
                ...state,
                family_details: action.payload
            }
        default:
            return state
    }
}