import API from "../../Network/API"

export const USER_INFO = 'USER_INFO'
export const EMP_PERSONAL_DETAILS = 'EMP_PERSONAL_DETAILS'
export const EMP_PUNISHMENT_DETAILS = 'EMP_PUNISHMENT_DETAILS'
export const EMP_COURSE_DETAILS = 'EMP_COURSE_DETAILS'
export const EMP_CADRES_DETAILS = "EMP_CADRES_DETAILS"
export const EMP_ERES_DETAILS = "EMP_ERES_DETAILS"
export const EMP_PERSONAL_LOAN_DETAILS = "EMP_PERSONAL_LOAN_DETAILS"
export const EMP_PROMOTION_DETAILS = "EMP_PROMOTION_DETAILS"
export const EMP_AFPP_DETAILS = "EMP_AFPP_DETAILS"
export const EMP_MACP_DETAILS = "EMP_MACP_DETAILS"
export const EMP_SERVICE_RETN_BD_DETAILS = "EMP_SERVICE_RETN_BD_DETAILS"
export const EMP_BANK_DETAILS = "EMP_BANK_DETAILS"
export const EMP_JOINT_ACCOUNT_DETAILS = "EMP_JOINT_ACCOUNT_DETAILS"
export const EMP_BPET_PPT_DETAILS = "EMP_BPET_PPT_DETAILS"
export const EMP_FIRING_DETAILS = "EMP_FIRING_DETAILS"
export const EMP_HOSPITALIZATION_DETAILS = "EMP_HOSPITALIZATION_DETAILS"
export const EMP_INJURY_PART2_ORDER_DETAILS = "EMP_INJURY_PART2_ORDER_DETAILS"
export const EMP_LVE_DETAILS = "EMP_LVE_DETAILS"
export const EMP_ACC_LVE_DETAILS = "EMP_ACC_LVE_DETAILS"
export const EMP_MARITIAL_DISCORD_CASE_DETAILS = "EMP_MARITIAL_DISCORD_CASE_DETAILS"
export const EMP_CIVIL_EDN_DETAILS = "EMP_CIVIL_EDN_DETAILS"
export const EMP_MIL_EDN_DETAILS = "EMP_MIL_EDN_DETAILS"
export const EMP_CANTEEN_CARD_DETAILS = "EMP_CANTEEN_CARD_DETAILS"
export const EMP_DEPENDENT_CARD_DETAILS = "EMP_DEPENDENT_CARD_DETAILS"
export const EMP_NOK_DETAILS = "EMP_NOK_DETAILS"
export const EMP_INTERVIEW_DETAILS = "EMP_INTERVIEW_DETAILS"
export const EMP_FAMILY_DETAILS = "EMP_FAMILY_DETAILS"

export const userInfo = (data) => ({
    type: USER_INFO,
    payload: data
})

export const emp_personal_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_personal_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_PERSONAL_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_PERSONAL_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_punishment_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_punishment_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_PUNISHMENT_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_PUNISHMENT_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_course_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_course_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_COURSE_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_COURSE_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_cadres_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_cadres_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_CADRES_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_CADRES_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_eres_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_eres_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_ERES_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_ERES_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_personal_loan_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_personal_loan_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_PERSONAL_LOAN_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_PERSONAL_LOAN_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_promotion_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_promotion_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_PROMOTION_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_PROMOTION_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_afpp_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_afpp_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_AFPP_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_AFPP_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_macp_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_macp_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_MACP_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_MACP_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_service_retn_bd_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_service_retn_bd_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_SERVICE_RETN_BD_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_SERVICE_RETN_BD_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_bank_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_bank_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_BANK_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_BANK_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_joint_account_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_joint_account_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_JOINT_ACCOUNT_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_JOINT_ACCOUNT_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_bpet_ppt_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_bpet_ppt_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_BPET_PPT_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_BPET_PPT_DETAILS,
                    payload: []
                });
            })
    }
}

export const emp_firing_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_firing_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_FIRING_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_FIRING_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_hospitalization_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_hospitalization_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_HOSPITALIZATION_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_HOSPITALIZATION_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_injury_part2_order_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_injury_part2_order_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_INJURY_PART2_ORDER_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_INJURY_PART2_ORDER_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_lve_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_lve_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_LVE_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_LVE_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_acc_lve_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_acc_lve_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_ACC_LVE_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_ACC_LVE_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_maritial_discord_case_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_maritial_discord_case_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_MARITIAL_DISCORD_CASE_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_MARITIAL_DISCORD_CASE_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_civil_edn_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_civil_edn_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_CIVIL_EDN_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_CIVIL_EDN_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_mil_edn_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_mil_edn_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_MIL_EDN_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_MIL_EDN_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_canteen_card_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_canteen_card_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_CANTEEN_CARD_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_CANTEEN_CARD_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_dependent_card_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_dependent_card_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_DEPENDENT_CARD_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_DEPENDENT_CARD_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_nok_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_nok_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_NOK_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_NOK_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_interview_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_interview_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_INTERVIEW_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_INTERVIEW_DETAILS,
                    payload: []
                });
            })
    }
}
export const emp_family_details = (emp_id) => {
    return async (dispatch) => {
        await API.post(`/emp_block/get_emp_family_details`, { emp_id: emp_id }, {}, true)
            .then(response => {
                dispatch({
                    type: EMP_FAMILY_DETAILS,
                    payload: response.data
                });
            }).catch(err => {
                dispatch({
                    type: EMP_FAMILY_DETAILS,
                    payload: []
                });
            })
    }
}










