import axios from 'axios'
import { BACKEND_URL } from '../../config';
import { getToken } from '../Storage';


export const axiosInstance = axios.create({
    headers: {
        'Accept': 'application/json',
    },
    baseURL: BACKEND_URL, //server url from config.js on v2.0.5
    responseType: "json"
});

const requestHandler = (request) => {
    if (request.headers.isTokenRequired) {
        const token = getToken('auth'); // Assuming getToken is a defined function
        request.headers['x-access-token'] = token;
    }

    delete request.headers.isTokenRequired;
    return request;

};


const errorHandler = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 400:
            case 401:
            case 406:
            case 409:
                throw Error(err.response.data.message);
            case 404:
                throw Error("Page Not Found");
            case 500:
                throw Error("Service Not Available");
            default:
                throw Error("Sorry Something Went Wrong");
        }
    }
    throw err
}
axiosInstance.interceptors.request.use((request) => requestHandler(request))
axiosInstance.interceptors.response.use(
    response => response
    , error => errorHandler(error)
)

