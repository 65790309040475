import React, { useEffect, useState } from 'react'
import TopNav from '../../../Components/TopNav'
import { useNavigate } from 'react-router'
import API from '../../../Common/Network/API';
import { Modal } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import Loader from '../../../Components/Loader';
import CandidateTable from '../../../Components/Table';
import { toastWarning } from '../../../Common/Toast';
import { removeToken } from '../../../Common/Storage';
import { advanceSearchTypes, familyRelationTypes } from '../../../Common/utilities';
import { loanTypes } from '../../../Common/utilities';
import { macpTypes } from '../../../Common/utilities';
import { searchTypes } from '../../../Common/utilities';
import { bankAccountTypes } from '../../../Common/utilities';
import { lveTypes } from '../../../Common/utilities';
import { civilEdnTypes } from '../../../Common/utilities';
import { milEdnTypes } from '../../../Common/utilities';
import { promotionDetailsTypes } from '../../../Common/utilities';
// import { POSITION } from 'react-toastify/dist/utils';

export default function SearchEmployee() {
    const navigate = useNavigate()
    const location = useLocation();
    const [globalSearch, setGlobalSearch] = useState(false)
    const [searchData, setSearchData] = useState({
        name: null,
        rank: null,
        location: null
    })
    const [resultData, setResultData] = useState([])
    const [recentSearchData, setRecentSearchData] = useState([])
    const [showFilter, setShowFilter] = useState({
        family: false,
        bank: false,
        hospitalizationDetails: false,
        punishment: false,
        courses: false,
        advanceSearch: false
    });
    const [advanceSearchData, setAdvanceSearchData] = useState({
        rank: null, part2: null, age: null, occupation: null, qualification: null, address: null,
        relation_type: null, name: null, bank_name: null, account_type: null, account_no: null, hospital_name: null, admission_date: null, discharge_date: null, course: null, grading: null, year: null, cadre: null, trade: null, duration: null, result: null, mm: null, fc: null, ss: null, dt: null, aa: null, date: null, remarks: null, punishment: null, offence: null, awarded_by: null, withdrawn_amount: null, indl_grocery: null, indl_liquor: null, d_i: null, d_ii: null, nok_name: null, dob: null, relation: null, loaning_agency: null, interest_rate: null, type_of_loan: null, civil_edn_type: null, civil_edn_year: null, no_of_court_hearings: null, next_court_hearing_date: null, court_of_justice: null, maint_allce: null, number_of_days_accumulated: null, no_of_years: null, cat: null, sustained_injury_on: null, injury_due_to: null, attr: null, not_attr: null,  relation_name: null,  lve_type: null, lve_details: null, part2_order_no: null, since: null,
        coy_cdrs_points: null, pl_cdrs_points: null, cos_points: null, ere_name: null, duration_from: null, duration_to: null, rank_type: null, rank_date: null, granted: null, not_granted: null, mil_edn_type: null, mil_edn_year: null, ex: null, good: null, sat: null, fail: null, no: null,coy_pl:null,doe:null,dom:null,spouse_name:null,father_name:null,mother_name:null,aadhar_card:null,pan_card:null,email_id:null,country:null,state:null,pincode:null,name_of_buddy:null,mobile:null
    })

    console.log(advanceSearchData)

    const [loader, setLoader] = useState(false);
    const [filterView, setFilterView] = useState(false);
    const [advanceFilterView, setAdvanceFilterView] = useState(false)
    const [filterType, setFilterType] = useState(null)
    const [empView, setEmpView] = useState(true)

    const buttonFilterData = [
        { label: 'Family', filter: 'family' },
        { label: 'Bank', filter: 'bank' },
        { label: 'Hospitalization', filter: 'hospitalization' },
        // { label: 'Punishment', filter: 'punishment' },
        { label: 'Courses', filter: 'courses' },
        { label: 'Cadres', filter: 'cadres' },
    ];
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setFilterType(e.target.value)
    };


    const handleAdvanceSearchData = (event) => {
        setAdvanceSearchData({ ...advanceSearchData, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        setGlobalSearch(!globalSearch)
        gerRecentSearches()
    }, [])

    useEffect(() => {
        console.log(location)
        if (location.state !== null) {
            setSearchData(location.state.searchData)
            setResultData(location.state.data)
            setFilterView(true)
        }
    }, [location.state])


    const gerRecentSearches = () => {
        API.get('/search_block/getRecentSearches', true)
            .then((result) => {
                setRecentSearchData(result.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleGlobalSearch = () => {
        setLoader(true)
        API.post(`search_block/globalSearch`, searchData, {}, true)
            .then((result) => {
                setFilterView(true)
                setLoader(false)
                setResultData(result.data)
                setEmpView(!empView)
            })
            .catch((err) => {
                setLoader(false)
                toastWarning(err.message)
            })
    }

    const handleSetFilter = (type) => {
        setFilterType(type)
        if (advanceFilterView) {
            switch (type) {
                case 'family':
                    setShowFilter({
                        family: true
                    });
                    break;
                case 'bank':
                    setShowFilter({
                        bank: true
                    });
                    break;
                case 'hospitalization':
                    setShowFilter({
                        hospitalization: true
                    });
                    break;
                case 'punishment':
                    setShowFilter({
                        punishment: true
                    });
                    break;
                case 'courses':
                    setShowFilter({
                        courses: true
                    });
                    break;
                case 'cadres':
                    setShowFilter({
                        cadres: true
                    });
                    break;
                case 'advanceSearch':
                    setShowFilter({
                        advanceSearch: true
                    });
                    break;
                default:
                    break;
            }

        } else {
            setLoader(true)
            API.post(`search_block/getDataByFilter`, { filterType: type, searchData: searchData }, {}, true)
                .then((result) => {
                    setLoader(false)
                    setResultData(result.data)
                    setAdvanceFilterView(true)
                })
                .catch((err) => {
                    setAdvanceFilterView(true)
                    setLoader(false)
                    console.log(err)
                })
        }
    }


    const handleClose = (type) => {
        switch (type) {
            case 'family':
                setShowFilter({
                    family: false
                });
                break;
            case 'bank':
                setShowFilter({
                    bank: false
                });
                break;
            case 'hospitalization':
                setShowFilter({
                    hospitalizationDetails: false
                });
                break;
            case 'punishment':
                setShowFilter({
                    punishment: false
                });
                break;
            case 'courses':
                setShowFilter({
                    courses: false
                });
                break;
            case 'cadres':
                setShowFilter({
                    cadres: false
                });
                break;
            case 'advanceSearch':
                setShowFilter({
                    advanceSearch: false
                });
                break;
            default:
                break;
        }
    };


    const handleAdvanceSearch = (e) => {
        e.preventDefault()
        setLoader(true)
        let mainSearch = {
            mainRank: searchData.rank,
            mainName: searchData.name,
            mainLocation: searchData.location
        }
        API.post(`search_block/advanceSearchFilter`, { mainSearch, filterType, advanceSearchData }, {}, true)
            .then((result) => {
                setLoader(false)
                setResultData(result.data)
                setShowFilter({
                    family: false,
                    bank: false,
                    hospitalizationDetails: false,
                    punishment: false,
                    courses: false,
                    advanceSearch: false
                })
                setAdvanceSearchData({
                    rank: null, part2: null, age: null, occupation: null, qualification: null, address: null,
                    relation_type: null, name: null, bank_name: null, account_type: null, account_no: null, hospital_name: null, admission_date: null, discharge_date: null, course: null, grading: null, year: null, cadre: null, trade: null, duration: null, result: null, mm: null, fc: null, ss: null, dt: null, aa: null, date: null, remarks: null, punishment: null, offence: null, awarded_by: null, withdrawn_amount: null, indl_grocery: null, indl_liquor: null, d_i: null, d_ii: null, nok_name: null, dob: null, relation: null, loaning_agency: null, interest_rate: null, type_of_loan: null, civil_edn_type: null, civil_edn_year: null, no_of_court_hearings: null, next_court_hearing_date: null, court_of_justice: null, maint_allce: null, number_of_days_accumulated: null, no_of_years: null, cat: null, sustained_injury_on: null, injury_due_to: null, attr: null, not_attr: null,  relation_name: null,  lve_type: null, lve_details: null, part2_order_no: null, since: null,
                    coy_cdrs_points: null, pl_cdrs_points: null, cos_points: null, ere_name: null, duration_from: null, duration_to: null, rank_type: null, rank_date: null, granted: null, not_granted: null, mil_edn_type: null, mil_edn_year: null, ex: null, good: null, sat: null, fail: null, no: null,coy_pl:null,doe:null,dom:null,spouse_name:null,father_name:null,mother_name:null,aadhar_card:null,pan_card:null,email_id:null,country:null,state:null,pincode:null,name_of_buddy:null,mobile:null
                })
            })
            .catch((err) => {
                setLoader(false)
                console.log(err)
            })
    }

    const handleSearchChange = (e) => {
        setSearchData({
            ...searchData, [e.target.name]: e.target.value
        })
    }

    return (
        <div>
            <TopNav />
            <div>
                <div>
                    {/* <SideNav /> */}
                    <main className="col-md-12 ms-sm-auto col-lg-12 ">
                        <div className='search-view'>
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="me-2">
                                        <button onClick={() => navigate('/dashboard')} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-house"></i> Dashboard</button>
                                        <button onClick={() => {
                                            removeToken('empAddToken')
                                            removeToken('empName')
                                            window.location.href = '/personalDetails'
                                        }} type="button" className="btn btn-sm btn-outline-primary m-1"><i className="bi bi-plus-circle"></i> Add Employee</button>
                                    </div>
                                </div>

                            </div>
                            <div className='search-component'>
                                {globalSearch === true ?
                                    <div className=" container">
                                        <div>
                                            <form>
                                                <div className=''>
                                                    <div className='col-12 row'>
                                                        <div className='col-3 p-0'>
                                                            <input name="name" type="search" className='form-control input-height' placeholder='Name' value={searchData.name} onChange={(e) => handleSearchChange(e)} />
                                                        </div>
                                                        <div className='col-3 p-0'>
                                                            <input name="rank" type="search" className='form-control input-height' placeholder='Rank' value={searchData.rank} onChange={(e) => handleSearchChange(e)} />
                                                        </div>
                                                        <div className='col-3 p-0'>
                                                            <input name="location" type="search" className='form-control input-height' placeholder='Location' value={searchData.location} onChange={(e) => handleSearchChange(e)} />
                                                        </div>
                                                        <div className='col-3 m-0'>
                                                            <button onClick={() => handleGlobalSearch()} type="button" className='btn btn-primary input-height-button'><i class="bi bi-search"></i> Search</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    : null
                                }
                                {
                                    filterView &&
                                    <div className='mt-2'>
                                        {
                                            buttonFilterData.map((button, index) => (
                                                <button
                                                    className="btn btn-outline-primary btn-sm m-1 rounded-pill"
                                                    type="button"
                                                    onClick={() => handleSetFilter(button.filter)}
                                                    key={index}
                                                >
                                                    {button.label} {advanceFilterView && <i class="bi bi-caret-down"></i>}
                                                </button>
                                            ))
                                        }
                                        {
                                            filterView && <button
                                                className="btn btn-outline-primary btn-sm m-1 rounded-pill"
                                                type="button"
                                                onClick={() => {
                                                    setFilterType('personalDetails')
                                                    setSelectedOption('personalDetails')
                                                    setShowFilter({
                                                        advanceSearch: true
                                                    })
                                                }}
                                            >
                                                Advance Search {advanceFilterView && <i class="bi bi-caret-down"></i>}
                                            </button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            loader && <Loader />
                        }
                        <CandidateTable employeeData={resultData} showEmpView={empView} />
                    </main>
                </div>
            </div >
            <Modal show={showFilter?.advanceSearch} onHide={() => handleClose('advanceSearch')} dialogClassName="modal-container slideInRight right" contentClassName="modal-right-content" className={showFilter?.advanceSearch ? 'slideInRight' : 'slideOutRight'}>
                <Modal.Header closeButton>
                    <Modal.Title>Advance Search</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="search_by" className="form-label">SEARCH BY</label>
                            <select className='form-select form-select-sm' value={selectedOption} onChange={(e) => handleOptionChange(e)}  >
                                {
                                    searchTypes.map((item, index) => {
                                        return <option key={index} value={item.value}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        {selectedOption === 'personalDetails' &&
                            (<fieldset>
                                <div className="m-1"> PersonalDetails
                                    <br />
                                    <label htmlFor="no" className="form-label">No</label>
                                    <input id="no" className='form-control form-control-sm' name="no" type="number" value={advanceSearchData.name} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="rank" className="form-label">Rank</label>
                                    <input id="rank" className='form-control form-control-sm' name="rank" type="text" value={advanceSearchData.rank} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="trade" className="form-label">Trade</label>
                                    <input id="trade" className='form-control form-control-sm' name="trade" type="text" value={advanceSearchData.trade} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="coy_pl" className="form-label">COY/PL</label>
                                    <input id="coy_pl" className='form-control form-control-sm' name="coy_pl" type="text" value={advanceSearchData.part2} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="dob" className="form-label">Date Of Birth</label>
                                    <input id="dob" className='form-control form-control-sm' name="dob" type="date" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="doe" className="form-label">Date Of Employement</label>
                                    <input id="doe" className='form-control form-control-sm' name="doe" type="date" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="dom" className="form-label">Date Of Marriage</label>
                                    <input id="dom" className='form-control form-control-sm' name="dom" type="date" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="spouse_name" className="form-label">Name Of Spouse</label>
                                    <input id="spouse_name" className='form-control form-control-sm' name="spouse_name" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="father_name" className="form-label">Name Of Father</label>
                                    <input id="father_name" className='form-control form-control-sm' name="father_name" type="text" value={advanceSearchData.occupation} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="mother_name" className="form-label">Name Of Mother</label>
                                    <input id="mother_name" className='form-control form-control-sm' name="mother_name" type="text" value={advanceSearchData.qualification} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="aadhar_card" className="form-label">Unique Identification Number(Aadhar)</label>
                                    <input id="aadhar_card" className='form-control form-control-sm' name="aadhar_card" type="number" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="pan_card" className="form-label">Pan Card</label>
                                    <input id="pan_card" className='form-control form-control-sm' name="pan_card" type="text" value={advanceSearchData.occupation} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="email_id" className="form-label">Email Id</label>
                                    <input id="email_id" className='form-control form-control-sm' name="email_id" type="text" value={advanceSearchData.qualification} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="name_of_buddy" className="form-label">Name of Buddy</label>
                                    <input id="name_of_buddy" className='form-control form-control-sm' name="name_of_buddy" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="mobile" className="form-label">Mobile Number</label>
                                    <input id="mobile" className='form-control form-control-sm' name="mobile" type="number" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="address" className="form-label">Address</label>
                                    <textarea id="address" className='form-control form-control-sm' name="address" type="text" value={advanceSearchData.address} onChange={handleAdvanceSearchData} col={5} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="city" className="form-label">City</label>
                                    <input id="city" className='form-control form-control-sm' name="city" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="state" className="form-label">State</label>
                                    <input id="state" className='form-control form-control-sm' name="state" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="country" className="form-label">Country</label>
                                    <input id="country" className='form-control form-control-sm' name="country" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="pincode" className="form-label">Pin Code</label>
                                    <input id="pincode" className='form-control form-control-sm' name="pincode" type="number" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="addl_special" className="form-label">Add Specialization(Trade Related)</label>
                                    <input id="addl_special" className='form-control form-control-sm' name="addl_special" type="text" value={advanceSearchData.age} onChange={handleAdvanceSearchData} />
                                </div>
                            </fieldset>)}
                        {selectedOption === 'punishment' &&
                            (<fieldset>
                                <div className="m-1"> PUNISHMENT </div>
                                <div className="m-1">
                                    <label htmlFor="punishment" className="form-label">Punishment</label>
                                    <input id="punishment" className='form-control form-control-sm' name="punishment" type="text" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="dt" className="form-label">DT</label>
                                    <input id="dt" className='form-control form-control-sm' name="dt" type="text" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="aa" className="form-label">AA</label>
                                    <input id="aa" className='form-control form-control-sm' name="aa" type="text" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="offence" className="form-label">Offense</label>
                                    <input id="offence" className='form-control form-control-sm' name="offence" type="text" onChange={handleAdvanceSearchData} />
                                </div>
                                <div className="m-1">
                                    <label htmlFor="awarded_by" className="form-label">Awarded By</label>
                                    <input id="awarded_by" className='form-control form-control-sm' name="awarded_by" type="text" onChange={handleAdvanceSearchData} />
                                </div>
                            </fieldset>)}
                        {selectedOption === 'courses' &&
                            (
                                <fieldset>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="course" className="form-label">Course</label>
                                        <input id="course" className='form-control form-control-sm' name="course" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="year" className="form-label">Year </label>
                                        <input id="year" className='form-control form-control-sm' name="year" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="grading" className="form-label">Grading</label>
                                        <input id="grading" className='form-control form-control-sm' name="grading" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="part2" className="form-label">Part II Order No</label>
                                        <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'cadres' &&
                            (
                                <fieldset>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="cadre" className="form-label">Cadre</label>
                                        <input id="cadre" className='form-control form-control-sm' name="cadre" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="duration" className="form-label">Duration </label>
                                        <input id="duration" className='form-control form-control-sm' name="duration" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="result" className="form-label">Result</label>
                                        <input id="result" className='form-control form-control-sm' name="result" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="part2" className="form-label">Part II Order No</label>
                                        <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'eres' &&
                            (
                                <fieldset>
                                    <div className="m-1"> ERE's </div>
                                    <div className="m-1">
                                        <label htmlFor="ere_name" className="form-label">NAME OF ERE BN</label>
                                        <input id="ere_name" className='form-control form-control-sm' name="ere_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="duration_from" className="form-label">DURATION FROM</label>
                                        <input id="duration_from" className='form-control form-control-sm' name="duration_from" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="duration_to" className="form-label">DURATION TO</label>
                                        <input id="duration_to" className='form-control form-control-sm' name="duration_to" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'loan' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Loan Details </div>
                                    <div className="m-1">
                                        <label htmlFor="type_of_loan" className="form-label">Type Of Loan</label>
                                        <select className='form-control form-control-sm' name="type_of_loan" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                loanTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="interest_rate" className="form-label">Interest Rate</label>
                                        <input id="interest_rate" className='form-control form-control-sm' name="interest_rate" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="emi" className="form-label">Emi</label>
                                        <input id="emi" className='form-control form-control-sm' name="emi" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="date" className="form-label">Date</label>
                                        <input id="date" className='form-control form-control-sm' name="date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="no_of_years" className="form-label">No of Years</label>
                                        <input id="no_of_years" className='form-control form-control-sm' name="no_of_years" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="loaning_agency" className="form-label">Loading Agency</label>
                                        <input id="loading_agency" className='form-control form-control-sm' name="loading_agency" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'promotionDetails' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Promotion Details </div>
                                    <div className="m-1">
                                        <label htmlFor="rank_type" className="form-label">Rank Type</label>
                                        <select className='form-control form-control-sm' name="rank_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                promotionDetailsTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="rank_date" className="form-label">Rank Date</label>
                                        <input id="rank_date" className='form-control form-control-sm' name="rank_date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'afpp' &&
                            (
                                <fieldset>
                                    <div className="m-1"> AFPP </div>
                                    <div className="m-1">
                                        <label htmlFor="withdrawn_amount" className="form-label">Total Amount Withdrawn</label>
                                        <input id="withdrawn_amount" className='form-control form-control-sm' name="withdrawn_amount" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="year" className="form-label">Year</label>
                                        <input id="year" className='form-control form-control-sm' name="year" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'macp' &&
                            (
                                <fieldset>
                                    <div className="m-1"> MACP </div>
                                    <div className="m-1">
                                        <label htmlFor="cat" className="form-label">CAT</label>
                                        <select className='form-control form-control-sm' name="cat" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                macpTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="cat_date" className="form-label">Date</label>
                                        <input id="cat_date" className='form-control form-control-sm' name="cat_date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'serviceRetnBd' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Service Retn BD </div>
                                    <div className="m-1">
                                        <label htmlFor="granted" className="form-label">Granted</label>
                                        <input id="granted" className='form-control form-control-sm' name="granted" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="not_granted" className="form-label">Not Granted</label>
                                        <input id="not_granted" className='form-control form-control-sm' name="not_granted" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'bank' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Bank Details</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="bank_name" className="form-label">Name Of Bank</label>
                                        <input id="bank_name" className='form-control form-control-sm' name="bank_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="account_type" className="form-label">Type Of Account</label>
                                        <input id="account_type" className='form-control form-control-sm' name="account_type" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="account_no" className="form-label">Acc No</label>
                                        <input id="account_no" className='form-control form-control-sm' name="account_no" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'jointAccount' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Joint Account Details</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="bank_name" className="form-label">Name Of Bank</label>
                                        <input id="bank_name" className='form-control form-control-sm' name="bank_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="joint_account_with" className="form-label">Joint Account With</label>
                                        <input id="joint_account_with" className='form-control form-control-sm' name="joint_account_with" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="acc_type" className="form-label">Type Of Account</label>
                                        <select className='form-select form-select-sm' name="acc_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                bankAccountTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="acc_no" className="form-label">Account Number</label>
                                        <input id="acc_no" className='form-control form-control-sm' name="acc_no" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'bpetPpt' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Bpet/Ppt</div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="date" className="form-label">Date</label>
                                        <input id="date" className='form-control form-control-sm' name="name" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="ex" className="form-label">Ex</label>
                                        <input id="ex" className='form-control form-control-sm' name="ex" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="good" className="form-label">Good</label>
                                        <input id="good" className='form-control form-control-sm' name="good" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="sat" className="form-label">Sat</label>
                                        <input id="sat" className='form-control form-control-sm' name="sat" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="fail" className="form-label">Fail</label>
                                        <input id="fail" className='form-control form-control-sm' name="fail" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'firing' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Firing</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="date" className="form-label">Date</label>
                                        <input id="date" className='form-control form-control-sm' name="name" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="mm" className="form-label">MM</label>
                                        <input id="mm" className='form-control form-control-sm' name="mm" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="fc" className="form-label">FC</label>
                                        <input id="fc" className='form-control form-control-sm' name="fc" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="ss" className="form-label">SS</label>
                                        <input id="ss" className='form-control form-control-sm' name="ss" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'hospitalization' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Hospitalization Deatils</div>
                                    <div className="m-1">
                                        <label htmlFor="hospital_name" className="form-label">Name Of Hospital</label>
                                        <input id="hospital_name" className='form-control form-control-sm' name="hospital_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="admission_date" className="form-label">Admission Date</label>
                                        <input id="admission_date" className='form-control form-control-sm' name="admission_date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="discharge_date" className="form-label">Discharge Date</label>
                                        <input id="discharge_date" className='form-control form-control-sm' name="discharge_date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="part2" className="form-label">Part II Order No</label>
                                        <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'injury' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Injury Part-II Order details</div>
                                    <div className="m-1">
                                        <label htmlFor="sustained_injury_on" className="form-label">Sustained Injury On</label>
                                        <input id="sustained_injury_on" className='form-control form-control-sm' name="sustained_injury_on" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="injury_due_to" className="form-label">Injury Due To</label>
                                        <input id="injury_due_to" className='form-control form-control-sm' name="injury_due_to" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="attr" className="form-label">Attr</label>
                                        <input id="attr" className='form-control form-control-sm' name="attr" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="not_attr" className="form-label">Not Attr</label>
                                        <input id="not_attr" className='form-control form-control-sm' name="not_attr" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="part2_order_no" className="form-label">Part-II Order No</label>
                                        <input id="part2_order_no" className='form-control form-control-sm' name="part2_order_no" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'lveDetails' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Lve Details</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="lve_type" className="form-label">Lve Type</label>
                                        <select className='form-control form-control-sm' name="lve_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                lveTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="lve_details" className="form-label">Lve Details</label>
                                        <input id="lve_details" className='form-control form-control-sm' name="lve_details" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="part2_order_no" className="form-label">Part-II Order No</label>
                                        <input id="part2_order_no" className='form-control form-control-sm' name="part2_order_no" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'accLve' &&
                            (
                                <fieldset>
                                    <div className="m-1">Acc Lve Details</div>
                                    <div className="m-1">
                                        <label htmlFor="number_of_days_accumulated" className="form-label">No Of Days Accumulated</label>
                                        <input id="number_of_days_accumulated" className='form-control form-control-sm' name="number_of_days_accumulated" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="year" className="form-label">Year</label>
                                        <input id="year" className='form-control form-control-sm' name="year" type="date" onChange={handleAdvanceSearchData} />
                                    </div>

                                    <div className="m-1">
                                        <label htmlFor="part2_order_no" className="form-label">Part-II Order No</label>
                                        <input id="part2_order_no" className='form-control form-control-sm' name="part2_order_no" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'martialDiscordCase' &&
                            (
                                <fieldset>
                                    <div className="m-1">Marital Discord Case Details</div>
                                    <div className="m-1">
                                        <label htmlFor="since" className="form-label">Since</label>
                                        <input id="since" className='form-control form-control-sm' name="since" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="no_of_court_hearings" className="form-label">No Of court Hearings</label>
                                        <input id="no_of_court_hearings" className='form-control form-control-sm' name="no_of_court_hearings" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="next_court_hearing_date" className="form-label">Next Court Hearing date</label>
                                        <input id="next_court_hearing_date" className='form-control form-control-sm' name="next_court_hearing_date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="court_of_justice" className="form-label">Court Of Justice</label>
                                        <input id="court_of_justice" className='form-control form-control-sm' name="court_of_justice" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="maint_allce" className="form-label">Maint Allice</label>
                                        <input id="maint_allce" className='form-control form-control-sm' name="maint_allce" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'civilEdn' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Civil Edn Details</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="civil_edn_type" className="form-label">Civil Edn Type</label>
                                        <select className='form-select form-select-sm' name="civil_edn_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                civilEdnTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="civil_edn_year" className="form-label">Civil Edn Year</label>
                                        <input id="civil_edn_year" className='form-control form-control-sm' name="civil_edn_year" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'milEdn' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Mil Edn Details</div>
                                    <div className="m-1">
                                        <label htmlFor="rank" className="form-label">Rank</label>
                                        <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="mil_edn_type" className="form-label">Mil Edn Type</label>
                                        <select className='form-select form-select-sm' name="mil_edn_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                milEdnTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="mil_edn_year" className="form-label">Mil Edn Year</label>
                                        <input id="mil_edn_year" className='form-control form-control-sm' name="mil_edn_year" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'canteenCard' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Canteen Card Details</div>
                                    <div className="m-1">
                                        <label htmlFor="indl_grocery" className="form-label">Indl Grocery</label>
                                        <input id="indl_grocery" className='form-control form-control-sm' name="indl_grocery" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="indl_liquor" className="form-label">Indl Liquor</label>
                                        <input id="indl_liquor" className='form-control form-control-sm' name="indl_liquor" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="d_i" className="form-label">D-I</label>
                                        <input id="d_i" className='form-control form-control-sm' name="d_i" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="d_ii" className="form-label">D-II</label>
                                        <input id="d_ii" className='form-control form-control-sm' name="d_ii" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'dependentCard' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Dependent Card Details</div>
                                    <div className="m-1">
                                        <label htmlFor="relation_type" className="form-label">Relation Type</label>
                                        <select className='form-control form-control-sm' name="relation_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                familyRelationTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="relation_name" className="form-label">Relation Name</label>
                                        <input id="relation_name" className='form-control form-control-sm' name="relation_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'nok_details' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Nok Details</div>
                                    <div className="m-1">
                                        <label htmlFor="nok_name" className="form-label">Name Of Nok</label>
                                        <input id="nok_name" className='form-control form-control-sm' name="nok_name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="dob" className="form-label">Date Of Birth</label>
                                        <input id="dob" className='form-control form-control-sm' name="dob" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="age" className="form-label">Age</label>
                                        <input id="age" className='form-control form-control-sm' name="age" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="relation" className="form-label">Relation</label>
                                        <select className='form-control form-control-sm' name="relation" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                familyRelationTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="occupation" className="form-label">Occupation</label>
                                        <input id="occupation" className='form-control form-control-sm' name="occupation" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'interview' &&
                            (
                                <fieldset>
                                    <div className="m-1"> Interview Details</div>
                                    <div className="m-1">
                                        <label htmlFor="date" className="form-label">Date</label>
                                        <input id="date" className='form-control form-control-sm' name="date" type="date" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="pl_cdrs_points" className="form-label">Pl Cdr's Points</label>
                                        <input id="pl_cdrs_points" className='form-control form-control-sm' name="pl_cdrs_points" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="cos_points" className="form-label">Co's Points</label>
                                        <input id="cos_points" className='form-control form-control-sm' name="cos_points" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="coy_cdrs_points" className="form-label">Coy Cdr's Ponits</label>
                                        <input id="coy_cdrs_points" className='form-control form-control-sm' name="coy_cdrs_points" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="remarks" className="form-label">Remarks</label>
                                        <input id="remarks" className='form-control form-control-sm' name="remarks" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {selectedOption === 'family' &&
                            (
                                <fieldset>
                                    <div className="m-1">Family Details</div>
                                    <div className="m-1">
                                        <label htmlFor="relation_type" className="form-label">Relation Type</label>
                                        <select className='form-control form-control-sm' name="relation_type" onChange={handleAdvanceSearchData}>
                                            <option value="">Select One</option>
                                            {
                                                familyRelationTypes.map((item, index) => {
                                                    return <option key={index} value={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="m-1">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                                    </div>
                                </fieldset>)}
                        {/* need to add family, bank, hospitalization, courses, cadres */}
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFilter?.family} onHide={() => handleClose('family')}>
                <Modal.Header closeButton>
                    <Modal.Title>Family Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="relation_type" className="form-label">Relation Type</label>
                            <select className='form-control form-control-sm' name="relation_type" onChange={handleAdvanceSearchData}>
                                <option value="">Select One</option>
                                {
                                    familyRelationTypes.map((item, index) => {
                                        return <option key={index} value={item.value}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="m-1">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFilter?.bank} onHide={() => handleClose('bank')}>
                <Modal.Header closeButton>
                    <Modal.Title>Bank Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="rank" className="form-label">Rank</label>
                            <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="bank_name" className="form-label">Name Of Bank</label>
                            <input id="bank_name" className='form-control form-control-sm' name="bank_name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="account_type" className="form-label">Type Of Account</label>
                            <input id="account_type" className='form-control form-control-sm' name="account_type" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="account_no" className="form-label">Acc No</label>
                            <input id="account_no" className='form-control form-control-sm' name="account_no" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFilter?.hospitalization} onHide={() => handleClose('hospitalization')}>
                <Modal.Header closeButton>
                    <Modal.Title>Hospitalization Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="hospital_name" className="form-label">Name Of Hospital</label>
                            <input id="hospital_name" className='form-control form-control-sm' name="hospital_name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="admission_date" className="form-label">Admission Date</label>
                            <input id="admission_date" className='form-control form-control-sm' name="admission_date" type="date" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="discharge_date" className="form-label">Discharge Date</label>
                            <input id="discharge_date" className='form-control form-control-sm' name="discharge_date" type="date" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="part2" className="form-label">Part II Order No</label>
                            <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFilter?.punishment} onHide={() => handleClose('punishment')}>
                <Modal.Header closeButton>
                    <Modal.Title>Punishment Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="punishment" className="form-label">Punishment</label>
                            <input id="punishment" className='form-control form-control-sm' name="punishment" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="dt" className="form-label">DT</label>
                            <input id="dt" className='form-control form-control-sm' name="dt" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="aa" className="form-label">AA</label>
                            <input id="aa" className='form-control form-control-sm' name="aa" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="defense" className="form-label">Defense</label>
                            <input id="defense" className='form-control form-control-sm' name="defense" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="awarded_by" className="form-label">Awarded By</label>
                            <input id="awarded_by" className='form-control form-control-sm' name="awarded_by" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={showFilter?.courses} onHide={() => handleClose('courses')}>
                <Modal.Header closeButton>
                    <Modal.Title>Courses Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="rank" className="form-label">Rank</label>
                            <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="course" className="form-label">Course</label>
                            <input id="course" className='form-control form-control-sm' name="course" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="year" className="form-label">Year </label>
                            <input id="year" className='form-control form-control-sm' name="year" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="grading" className="form-label">Grading</label>
                            <input id="grading" className='form-control form-control-sm' name="grading" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="part2" className="form-label">Part II Order No</label>
                            <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFilter?.cadres} onHide={() => handleClose('cadres')}>
                <Modal.Header closeButton>
                    <Modal.Title>Cadres Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => handleAdvanceSearch(e)}>
                        <div className="m-1">
                            <label htmlFor="rank" className="form-label">Rank</label>
                            <input id="rank" className='form-control form-control-sm' name="rank" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input id="name" className='form-control form-control-sm' name="name" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="cadre" className="form-label">Cadre</label>
                            <input id="cadre" className='form-control form-control-sm' name="cadre" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="duration" className="form-label">Duration </label>
                            <input id="duration" className='form-control form-control-sm' name="duration" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="result" className="form-label">Result</label>
                            <input id="result" className='form-control form-control-sm' name="result" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <label htmlFor="part2" className="form-label">Part II Order No</label>
                            <input id="part2" className='form-control form-control-sm' name="part2" type="text" onChange={handleAdvanceSearchData} />
                        </div>
                        <div className="m-1">
                            <button type="submit" className='btn btn-outline-primary btn-sm m-1 rounded-pill'>Search</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div >

    )
}