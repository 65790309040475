import React, { useState } from 'react'
import API from '../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../Common/Toast'
import { setToken } from '../../Common/Storage'
import { useDispatch } from 'react-redux';
import { userInfo } from '../../Common/Redux/Actions';
import { useNavigate } from 'react-router-dom'
import { BUILD_DATE, BUILD_VERSION } from '../../config';
export default function AdminLogin() {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [data, setData] = useState()

    const handleLogin = (e) => {
        e.preventDefault()
        API.post(`/login_block/super_admin_login`, data,
            {}, false).then(res => {
                // toastSuccess('Loggedin!')
                setToken('auth', res.token)
                dispatch(userInfo(res));
                navigate('/adminDashboard')
            }).catch(err => {
                toastWarning(err.message)
            })
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='login-header'>
            <div className='login-container text-center'>
                <main className="form-signin w-100 m-auto">
                    <form onSubmit={(e) => handleLogin(e)}>
                        <img className="mb-4" src="./images/emp_logo.png" alt="" width="300" height="70" />
                        {/* <h1 className="h3 mb-3 fw-normal">Welcome back to EMS!</h1> */}
                        <h5>Admin Sign in</h5>
                        <div className="form-floating">
                            <input name="email" type="email" className="form-control" id="floatingInput" placeholder='user@example.com' onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingInput">Email</label>
                        </div>
                        <div className="form-floating">
                            <input name="password" type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>

                        <div className="checkbox mb-3">
                            <label>
                                <input type="checkbox" value="remember-me" /> Remember me
                            </label>
                        </div>
                        <button className="w-100 mt-4 mb-1mar btn btn-sm btn-primary btn-color" type="submit">Sign in</button>
                        <p className="mt-5 mb-3 text-body-secondary">V {BUILD_VERSION} {BUILD_DATE} &copy; {`Novisync Inc ${new Date().getFullYear()}`}</p>
                    </form>
                </main>
            </div>
        </div>
    )
}