import React, { useEffect, useState } from 'react';
import TopNav from '../../Components/TopNav';
import { useNavigate } from 'react-router';
import { removeToken } from '../../Common/Storage';
import API from '../../Common/Network/API';
import SearchBar from '../../Components/Search/search';
import Loader from '../../Components/Loader';
import CandidateTable from '../../Components/Table';
import { Bar, Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

export default function Dashboard() {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [numberOfEmployees, setNumberOfEmployeesData] = useState([]);
  const [numberOfColonels, setNumberOfColonelsData] = useState([]);
  const [numberOfMajors, setNumberOfMajorsData] = useState([]);
  const [numberOfCaptains, setNumberOfCaptainsData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getEmployees();
    getNumberOfEmployees();
    getNumberOfMajors();
    getNumberOfColonels();
    getNumberOfCaptains();
    getBarGraph();
    getPieChart();
    removeToken('empViewToken');
  }, []);

  const getEmployees = () => {
    setLoader(true);
    API.get('/dashboard_block/get_employees', true)
      .then((response) => {
        setLoader(false);
        setEmployeeData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getNumberOfEmployees = () => {
    setLoader(true);
    API.get('/dashboard_block/get_number_of_employees', true)
      .then((response) => {

        setLoader(false);
        setNumberOfEmployeesData(response.data[0].numberOfEmployees);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };



  const getNumberOfMajors = () => {
    setLoader(true);
    API.get('/dashboard_block/get_number_of_majors', true)
      .then((response) => {
        setLoader(false);
        setNumberOfMajorsData(response.data[0].numberOfMajors);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getNumberOfColonels = () => {
    setLoader(true);
    API.get('/dashboard_block/get_number_of_colonels', true)
      .then((response) => {
        setLoader(false);
        setNumberOfColonelsData(response.data[0].numberOfColonels);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getNumberOfCaptains = () => {
    setLoader(true);
    API.get('/dashboard_block/get_number_of_captains', true)
      .then((response) => {
        setLoader(false);
        setNumberOfCaptainsData(response.data[0].numberOfCaptains);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getBarGraph = () => {
    setLoader(true);
    API.get('/dashboard_block/get_bar_graph', true)
      .then((response) => {
        setLoader(false);
        setBarGraphData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };


  const getPieChart = () => {
    setLoader(true);
    API.get('/dashboard_block/get_pie_chart', true)
      .then((response) => {
        setLoader(false);
        setPieChartData(response.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }
  const barChartData = {
    labels: barGraphData.map(item => { return item.state }),
    datasets: [
      {
        label: "No of Employees",
        data: barGraphData.map(item => { return item.count }),
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        barThickness: 20,
      },

    ],
  };

  const chartData = {
    labels: barGraphData.map((chart) => chart.state),
    datasets: [
      {
        label: 'Number of Employees',
        data: barGraphData.map((chart) => chart.employees),
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set the desired background color
      },
    ],
  };

  const pieChartGraphData = {
    labels: pieChartData.map(item => {return item.rank}),
    datasets: [
      {
        label: "Gender Distribution",
        data: pieChartData.map(item => {return item.count}),
        backgroundColor: ["#EBB8DD", "#53BDA5", "#F5C26B", "#5C62D6", "#33ECFF", "#592693", "#8F00FF"],
        borderColor: ["#EBB8DD", "#53BDA5", "#F5C26B", "#5C62D6", "#33ECFF", "#592693", "8F00"],
        borderWidth: 1,
      },
    ],
  };


  const getSearchResult = (data, searchData) => {
    navigate('/searchEmployee', { state: { data: data, searchData: searchData } });
  };

  return (
    <div>
      <TopNav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <SearchBar getSearchResult={getSearchResult} />
          </div>
        </div>

        {loader && <Loader />}
        <div className="col-md-12" style={{ marginTop: '20px', }}>
          <div className="container col-md-12 col-lg-12 row" style={{ justifyContent: 'space-around' }}>

            <div className="card col-3">
              <div className="card-body">
                <h6 className="card-title">No Of Employees <i className="bi bi-people"></i></h6>
                <p className="card-text h6">{numberOfEmployees}</p>
              </div>
            </div>

            <div className="card col-3">
              <div className="card-body">
                <h6 className="card-title">No Of Majors <i class="bi bi-people"></i></h6>
                <p className="card-text h6">{numberOfMajors}</p>
              </div>
            </div>
            <div className="card col-3">
              <div className="card-body">
                <h6 className="card-title">No Of Colonel <i class="bi bi-people"></i></h6>
                <p className="card-text h6">{numberOfColonels}</p>
              </div>
            </div>
            <div className="card col-3">
              <div className="card-body">
                <h6 className="card-title">No Of Captains <i class="bi bi-people"></i></h6>
                <p className="card-text h6">{numberOfCaptains}</p>
              </div>
            </div>

          </div>
          <div className='row'>
            <div className="col-md-8">
              <Bar
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      title: 'No.of employees from each state'
                    }
                  },
                }}
                data={barChartData}
                height="300px"
                width="200px"
              />

            </div>
            <div className="col-md-4 mt-3 mt-md-0">
              <Pie
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      title: 'No.of employees from Different Ranks'
                    }
                  },
                }}
                height="300px"
                width="200px"
                data={pieChartGraphData} />

            </div>
          </div>

        </div>


      </div>

      <CandidateTable employeeData={employeeData} />
    </div>
  );
}

