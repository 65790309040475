import React, { useEffect, useState } from 'react'
import API from '../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../Common/Toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { BUILD_DATE, BUILD_VERSION } from '../../config'

export default function OtpInput() {

    const navigate = useNavigate()
    const location = useLocation();
    useEffect(() => {
        if (location.state !== null) {
            setUserData(location.state)
        }
    }, [location.state])

    const [data, setData] = useState()
    const [userData, setUserData] = useState()

    console.log(userData)
    const handleOtpInput = (e) => {
        e.preventDefault()
        API.post(`/login_block/user_verify_otp`, {data :data, userData: userData},
            {}, false).then(res => {
                toastSuccess(res.message)
                navigate('/userResetPassword',{ state: res.data });
            }).catch(err => {
                toastWarning(err.message)
            })
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleResendOtp = () => {

        API.post(`/login_block/user_verify`, { username: userData[0].user_name },
            {}, false).then(res => {
                toastSuccess(res.message)
            }).catch(err => {
                toastWarning(err.message)
            })

    }
    return (
        <div className='login-header'>
            <div className='login-container text-center'>
                <main className="form-signin w-100 m-auto">
                    <form onSubmit={(e) => handleOtpInput(e)}>
                        <img className="mb-4" src="./images/emp_logo.png" alt="" width="300" height="70" />
                        <h5>Enter your OTP</h5>
                        <div className="form-floating">
                            <input name="otp" type="number" className="form-control" id="floatingInput" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingInput">Enter OTP</label>
                        </div>
                        <div className="checkbox mb-3 mt-1">
                            <label style={{ cursor: 'pointer' }} onClick={() => handleResendOtp()}>
                                Resend OTP
                            </label>
                        </div>
                        <button className="w-100 mt-4 mb-1 btn btn-sm btn-primary btn-color mt-1" type="submit">Submit</button>
                        <p className="mt-1 mb-3 text-body-secondary">V {BUILD_VERSION} {BUILD_DATE} &copy; {`Novisync Inc ${new Date().getFullYear()}`}</p>
                    </form>
                </main>
            </div>
        </div>
    )
}
