import React, { useEffect, useState } from 'react'
import API from '../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../Common/Toast'
import { useNavigate, useLocation } from 'react-router-dom'
import { BUILD_DATE, BUILD_VERSION } from '../../config';
export default function ResetPassword() {

    const navigate = useNavigate()
    const location = useLocation();
    useEffect(() => {
        if (location.state !== null) {
            setUserData(location.state)
        }
    }, [location.state])

    const [data, setData] = useState()
    const [userData, setUserData] = useState()


    const handleResetPassword = (e) => {
        e.preventDefault()
        if(data.newPassword === data.confirmPassword){
        API.post(`/login_block/user_update_password`, { data: data, userData: userData },
            {}, false).then(res => {
                toastSuccess(res.message)
                navigate('/');
            }).catch(err => {
                toastWarning(err.message)
            })
        }else{
            toastWarning("Both New Password & Confirm Password Should Match!")
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='login-header'>
            <div className='login-container text-center'>
                <main className="form-signin w-100 m-auto">
                    <form onSubmit={(e) => handleResetPassword(e)}>
                        <img className="mb-4" src="./images/emp_logo.png" alt="" width="300" height="70" />
                        {/* <h1 className="h3 mb-3 fw-normal">Welcome back to EMS!</h1> */}
                        <h5>Update Password</h5>
                        <div className="form-floating">
                            <input name="newPassword" type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingPassword">New Password</label>
                        </div>

                        <div className="form-floating">
                            <input name="confirmPassword" type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => handleChange(e)} required />
                            <label htmlFor="floatingPassword">Confirm Password</label>
                        </div>


                        <button className="w-100 mt-4 mb-1 btn btn-sm btn-primary btn-color mt-1" type="submit">Submit</button>
                        <p className="mt-1 mb-3 text-body-secondary">V {BUILD_VERSION} {BUILD_DATE} &copy; {`Novisync Inc ${new Date().getFullYear()}`}</p>
                    </form>
                </main>
            </div>
        </div>
    )
}
