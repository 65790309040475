import React, { useEffect, useState } from 'react'
import TopNav from '../../../Components/TopNav'
import { useNavigate } from 'react-router'
import SideNav from '../../../Components/SideNav'
import API from '../../../Common/Network/API'
import { toastSuccess, toastWarning } from '../../../Common/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { emp_personal_details } from '../../../Common/Redux/Actions'
import { getToken, setToken } from '../../../Common/Storage'
import ValidationPage from '../../../Components/ValidationPage';
import "../../../Components/indexc.css";


export default function PersonalDetails() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const dispatch = useDispatch()
    const reduxRersonalDetails = useSelector((state) => state?.empReducer?.personal_details)

    useEffect(() => {
        if (reduxRersonalDetails?.length > 0) {
            setData(reduxRersonalDetails[0])
            setToken("empName", `${reduxRersonalDetails[0].name}-${reduxRersonalDetails[0].rank}`)
        }
    }, [reduxRersonalDetails])

    useEffect(() => {
        if (getToken('empAddToken') !== null) {
            dispatch(emp_personal_details(getToken('empAddToken')))
        }
    }, [getToken('empAddToken')])

    const handleChange = (event) => {
        const { name, type } = event.target
        if (type === 'file' && event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
            if (fileSizeInMB > 1) {
                toastWarning('File size exceeds the limit of 1MB. Please choose a smaller file.');
                const fileInput = document.getElementById('imageFileInput');
                fileInput.value = ''; // Reset the file input value
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64Image = e.target.result;
                    setData({ ...data, [name]: base64Image });
                };
                reader.readAsDataURL(file);
            }
        } else {
            const value = event.target.value;
            setData({ ...data, [name]: value });
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (reduxRersonalDetails?.length > 0) {
            API.post(`/emp_block/update_emp_personal_details`, data, {}, true)
                .then(result => {
                    toastSuccess(result.message)
                    dispatch(emp_personal_details(result.emp_id))
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            API.post(`/emp_block/emp_personal_details`, data, {}, true)
                .then(result => {
                    setToken('empAddToken', result.emp_id)
                    toastSuccess(result.message)
                    dispatch(emp_personal_details(result.emp_id))
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div className='mb-5'>
            <TopNav />
            <div className="container-fluid">
                <div className="row">
                    <SideNav />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h3 className="h6">PERSONAL DETAILS : {getToken('empName')}</h3>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="me-2">
                                    <button onClick={() => navigate('/searchEmployee')} type="button" className="btn btn-sm btn-outline-primary m-1"><i className="bi bi-search"></i> Search Employee</button>
                                </div>
                            </div>
                        </div>

                        <label></label>
                        <div className="table-responsive">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='col-12 row'>
                                    <div className='col-lg-6 col-md-4'>
                                        <div className="m-1">
                                            <label htmlFor="no" className="form-label">NO*</label>
                                            <input id="no" value={data?.no} className='form-control form-control-sm' name="no" type="text" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="rank" className="form-label">Rank*</label>
                                            <input id="renk" value={data?.rank} className='form-control form-control-sm' name="rank" type="text" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="trade" className="form-label">Trade*</label>
                                            <input id="trade" value={data?.trade} className='form-control form-control-sm' name="trade" type="text" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="name" className="form-label">Full Name*</label>
                                            <input id="name" value={data?.name} className='form-control form-control-sm' name="name" type="text" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="coy_pl" className="form-label">COY/PL</label>
                                            <input id="coy_pl" value={data?.coy_pl} className='form-control form-control-sm' name="coy_pl" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="dob" className="form-label">DATE OF BIRTH*</label>
                                            <input id="dob" value={data?.dob} className='form-control form-control-sm' name="dob" type="date" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="doe" className="form-label">DATE OF EMPLOYEMENT*</label>
                                            <input id="doe" value={data?.doe} className='form-control form-control-sm' name="doe" type="date" onChange={(e) => handleChange(e)} required />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="dom" className="form-label">DATE OF MARRIAGE</label>
                                            <input id="dom" value={data?.dom} className='form-control form-control-sm' name="dom" type="date" onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="spouse_name" className="form-label">NAME OF SPOUSE</label>
                                            <input id="spouse_name" value={data?.spouse_name} className='form-control form-control-sm' name="spouse_name" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="father_name" className="form-label">FATHER NAME</label>
                                            <input id="father_name" value={data?.father_name} className='form-control form-control-sm' name="father_name" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="mother_name" className="form-label" >MOTHER NAME</label>
                                            <input id="mother_name" value={data?.mother_name} className='form-control form-control-sm' name="mother_name" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        {/*<div className="m-1">
                                            <label htmlFor="aadhar_card" className="form-label">AADHAR CARD</label>
                                            <input id="aadhar_card" value={data?.aadhar_card} className='form-control form-control-sm' name="aadhar_card" type="text" onChange={(e) => handleChange(e)} /></div>*/}
                                        {/*<div className="m-1">
                                            <label htmlFor="pan_card" className="form-label">PAN CARD</label>
                                            <input id="pan_card" value={data?.pan_card} className='form-control form-control-sm' name="pan_card" type="text" onChange={(e) => handleChange(e)} />
                                        </div>*/}
                                        <div className="m-1">
                                            <ValidationPage
                                                value={data?.aadhar_card}
                                                onChange={(e) => handleChange(e)}
                                                name="aadhar_card"
                                                label="UNIQUE IDENTIFICATION NUMBER(AADHAR)"
                                            />
                                        </div>
                                        <div className="m-1">
                                            <ValidationPage
                                                value={data?.pan_card}
                                                onChange={(e) => handleChange(e)}
                                                name="pan_card"
                                                label="PAN CARD"
                                            />
                                        </div>
                                        <div className="m-1">
                                            <ValidationPage
                                                value={data?.email}
                                                onChange={(e) => handleChange(e)}
                                                name="email"
                                                label="EMAIL ID"
                                                required
                                            />
                                        </div>
                                        <div className="m-1">

                                            {/* <label htmlFor="email_id" className="form-label">EMAIL ID</label>
                                            <ValidationPage value={data?.email}/>
                                            <input id="email_id" value={data?.email_id} className='form-control form-control-sm' name="email_id" type="text" onChange={(e) => handleChange(e)} />*/}
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="name_of_buddy" className="form-label">NAME OF BUDDY</label>
                                            <input id="name_of_buddy" value={data?.name_of_buddy} className='form-control form-control-sm' name="name_of_buddy" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        {/*<div className="m-1">
                                        
                                            {/*<label htmlFor="mobile" className="form-label">MOBILE NUMBER</label>
                                            <input id="mobile"  className='form-control form-control-sm' name="mobile" type="text" onChange={(e) => handleChange(e)} />
                                        
                                            </div>*/}

                                        <div className="m-1">
                                            <ValidationPage
                                                value={data?.mobile}
                                                onChange={(e) => handleChange(e)}
                                                name="mobile"
                                                label="MOBILE NUMBER"
                                                required
                                            />
                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="address" className="form-label">ADDRESS</label>
                                            <input id="address" value={data?.address} className='form-control form-control-sm' name="address" type="text" onChange={(e) => handleChange(e)} />
                                        </div>
                                        <div className='ml-1 row col-12'>
                                            <div className="col-6">
                                                <label htmlFor="city" className="form-label">CITY</label>
                                                <input id="city" value={data?.city} className='form-control form-control-sm' name="city" type="text" onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="state" className="form-label">STATE</label>
                                                <input id="state" value={data?.state} className='form-control form-control-sm' name="state" type="text" onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="country" className="form-label">COUNTRY</label>
                                                <input id="country" value={data?.country} className='form-control form-control-sm' name="country" type="text" onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="pincode" className="form-label">PINCODE</label>
                                                <input id="pincode" value={data?.pincode} className='form-control form-control-sm' name="pincode" type="text" onChange={(e) => handleChange(e)} />
                                            </div>

                                        </div>
                                        <div className="m-1">
                                            <label htmlFor="addl_special" className="form-label">ADD SPECIALIZATION(TRADE RELATED)</label>
                                            <input id="addl_special" value={data?.addl_special} className='form-control form-control-sm' name="addl_special" type="text" onChange={(e) => handleChange(e)} />
                                        </div>

                                    </div>
                                    <div className='col-lg-3' />
                                    <div className='col-lg-2 col-md-2'>
                                        <div className='card' style={{ width: '10rem', height: '150px' }}>
                                            <img src={data?.image_url} width={160} height={150} id="imageFileInput" />
                                        </div>
                                        <br />
                                        <input type="file" name="image_url" onChange={(e) => handleChange(e)} />
                                    </div>
                                    <br />
                                    <div className='col-lg-6 col-md-4'>
                                        <table className='table table-bordered table-heading'>
                                            <thead>
                                                <tr >
                                                    <th>SNO</th>
                                                    <th colSpan="2">CAT</th>
                                                    <th>PART-II NO</th>
                                                    <th>REMARKS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-th='SNO'>1</td>
                                                    <td data-th="CAT" colSpan="2">MARRIAGE</td>
                                                    <td data-th="PART-II NO"><input value={data?.marriage_part2_no} type="text" className='form-control form-control-sm' name="marriage_part2_no" onChange={(e) => handleChange(e)} /></td>
                                                    <td data-th="REMARKS"><input value={data?.marriage_remarks} type="text" className='form-control form-control-sm' name="marriage_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>2</td>
                                                    <td data-th="CAT" colSpan="2">BIRTH</td>
                                                    <td data-th="PART-II NO"><input value={data?.birth_part2_no} type="text" className='form-control form-control-sm' name="birth_part2_no" onChange={(e) => handleChange(e)} /></td>
                                                    <td data-th="REMARKS"><input value={data?.birth_remarks} type="text" className='form-control form-control-sm' name="birth_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>3</td>
                                                    <td data-th="CAT" colSpan="2">JOINT ACCOUNT</td>
                                                    <td data-th="PART-II NO"><input value={data?.jt_acct_part2_no} type="text" className='form-control form-control-sm' name="jt_acct_part2_no" onChange={(e) => handleChange(e)} /></td>
                                                    <td data-th="REMARKS"><input value={data?.jt_acct_remarks} type="text" className='form-control form-control-sm' name="jt_acct_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>4</td>
                                                    <td data-th="CAT" colSpan="2">UNIQUE IDENTIFICATION NUMBER(AADHAR)</td>
                                                    <td data-th="PART-II NO"><input value={data?.uid_part2_no} type="text" className='form-control form-control-sm' name="uid_part2_no" onChange={(e) => handleChange(e)} /></td>
                                                    <td data-th="REMARKS"><input value={data?.uid_remarks} type="text" className='form-control form-control-sm' name="uid_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>5</td>
                                                    <td data-th="CAT" colSpan="2">PAN CARD </td>
                                                    <td data-th="PART-II NO"><input value={data?.pan_part2_no} type="text" className='form-control form-control-sm' name="pan_part2_no" onChange={(e) => handleChange(e)} /></td>
                                                    <td data-th="REMARKS"><input value={data?.pan_remarks} type="text" className='form-control form-control-sm' name="pan_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>6</td>
                                                    <td data-th="CAT" colSpan="2">EMAIL ID</td>
                                                    <td data-th="PART-II NO"><input value={data?.email_part2_no} type="email" className='form-control form-control-sm' name="email_part2_no" onChange={(e) => handleChange(e)} /></td>

                                                    <td data-th="REMARKS"><input value={data?.email_remarks} type="text" className='form-control form-control-sm' name="email_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                                <tr>
                                                    <td data-th='SNO'>7</td>
                                                    <td data-th="CAT" colSpan="2">MOBILE PHONE NO</td>
                                                    <td data-th="PART-II NO"><input value={data?.mobile_part2_no} type="text" className='form-control form-control-sm' name="mobile_part2_no" onChange={(e) => handleChange(e)} /></td>

                                                    <td data-th="REMARKS"><input value={data?.mobile_remarks} type="text" className='form-control form-control-sm' name="mobile_remarks" onChange={(e) => handleChange(e)} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <button type="submit" className='btn btn-sm btn-primary m-1'>{reduxRersonalDetails?.length > 0 ? "Update" : "Save"}</button>
                                        <button type="button" onClick={() => navigate('/punishment')} className='btn btn-sm btn-primary m-1'>Next</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div >
        </div >
    )
}