import React, { useEffect } from 'react'
import { emp_acc_lve_details, emp_afpp_details, emp_bank_details, emp_bpet_ppt_details, emp_cadres_details, emp_canteen_card_details, emp_civil_edn_details, emp_course_details, emp_dependent_card_details, emp_eres_details, emp_family_details, emp_firing_details, emp_hospitalization_details, emp_injury_part2_order_details, emp_interview_details, emp_joint_account_details, emp_lve_details, emp_macp_details, emp_maritial_discord_case_details, emp_mil_edn_details, emp_nok_details, emp_personal_details, emp_personal_loan_details, emp_promotion_details, emp_punishment_details, emp_service_retn_bd_details } from '../../../Common/Redux/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../../Common/Storage';
import { formatMySQLDate } from '../../../Common/date';

export default function ViewEmployee(props) {

  const dispatch = useDispatch()
  useEffect(() => {
    if (getToken('empViewToken') !== null) {
      dispatch(emp_personal_details(getToken('empViewToken')))
      dispatch(emp_punishment_details((getToken('empViewToken'))))
      dispatch(emp_course_details(getToken('empViewToken')))
      dispatch(emp_cadres_details(getToken('empViewToken')))
      dispatch(emp_eres_details(getToken('empViewToken')))
      dispatch(emp_personal_loan_details(getToken('empViewToken')))
      dispatch(emp_promotion_details(getToken('empViewToken')))
      dispatch(emp_afpp_details(getToken('empViewToken')))
      dispatch(emp_macp_details(getToken('empViewToken')))
      dispatch(emp_service_retn_bd_details(getToken('empViewToken')))
      dispatch(emp_bank_details(getToken('empViewToken')))
      dispatch(emp_joint_account_details(getToken('empViewToken')))
      dispatch(emp_bpet_ppt_details(getToken('empViewToken')))
      dispatch(emp_firing_details(getToken('empViewToken')))
      dispatch(emp_hospitalization_details(getToken('empViewToken')))
      dispatch(emp_injury_part2_order_details(getToken('empViewToken')))
      dispatch(emp_lve_details(getToken('empViewToken')))
      dispatch(emp_acc_lve_details(getToken('empViewToken')))
      dispatch(emp_maritial_discord_case_details(getToken('empViewToken')))
      dispatch(emp_civil_edn_details(getToken('empViewToken')))
      dispatch(emp_mil_edn_details(getToken('empViewToken')))
      dispatch(emp_canteen_card_details(getToken('empViewToken')))
      dispatch(emp_dependent_card_details(getToken('empViewToken')))
      dispatch(emp_nok_details(getToken('empViewToken')))
      dispatch(emp_interview_details(getToken('empViewToken')))
      dispatch(emp_family_details(getToken('empViewToken')))
    }
  }, [getToken('empViewToken')])

  const personal_details = useSelector(state => state.empReducer?.personal_details || [])
  const punishment_details = useSelector(state => state.empReducer?.punishment_details)
  const course_details = useSelector(state => state.empReducer.course_details)
  const cadres_details = useSelector(state => state.empReducer.cadres_details)
  const eres_details = useSelector(state => state.empReducer.eres_details)
  const personal_loan_details = useSelector(state => state.empReducer.personal_loan_details)
  const promotion_details = useSelector(state => state.empReducer.promotion_details)
  const afpp_details = useSelector(state => state.empReducer.afpp_details)
  const macp_details = useSelector(state => state.empReducer.macp_details)
  const service_retn_bd_details = useSelector(state => state.empReducer.service_retn_bd_details)
  const bank_details = useSelector(state => state.empReducer.bank_details)
  const joint_account_details = useSelector(state => state.empReducer.joint_account_details)
  const bpet_ppt_details = useSelector(state => state.empReducer.bpet_ppt_details)
  const firing_details = useSelector(state => state.empReducer.firing_details)
  const hospitalization_details = useSelector(state => state.empReducer.hospitalization_details)
  const injury_part2_order_details = useSelector(state => state.empReducer.injury_part2_order_details)
  const lve_details = useSelector(state => state.empReducer.lve_details)
  const acc_lve_details = useSelector(state => state.empReducer.acc_lve_details)
  const maritial_discord_case_details = useSelector(state => state.empReducer.maritial_discord_case_details)
  const civil_edn_details = useSelector(state => state.empReducer.civil_edn_details)
  const mil_edn_details = useSelector(state => state.empReducer.mil_edn_details)
  const canteen_card_details = useSelector(state => state.empReducer.canteen_card_details)
  const dependent_card_details = useSelector(state => state.empReducer.dependent_card_details)
  const nok_details = useSelector(state => state.empReducer.nok_details)
  const interview_details = useSelector(state => state.empReducer.interview_details)
  const family_details = useSelector(state => state.empReducer.family_details)
  const navigate = useNavigate()

  return (
    <div>
      <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 mb-5">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-5 border-bottom">
          <h3 className="h6">{personal_details[0]?.name}{" "}{personal_details[0]?.rank}</h3>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="me-2">

              <button onClick={() => navigate('/searchEmployee')} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-search"></i> Search Employee</button>
              <button onClick={() => {
                removeToken('empViewToken')
                props.backButton()
              }} type="button" className="btn btn-sm btn-outline-primary m-1"><i class="bi bi-back"></i> Back</button>
            </div>
          </div>
        </div>

        <label style={{ color: '#5e5e5e' }}><h5><b>PERSONAL DETAILS</b></h5></label>
        <br />
        <div className="table-responsive">
          <br />
          <div className='col-12 row'>
            <div className='col-lg-6 col-md-4'>
              <div className='row col-12 m-1' >
                <div className="col-6">
                  <label><b>NO</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.no}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>RANK</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.rank}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>TRADE</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.trade}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>NAME</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.name}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>COY/PL</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.coy_pl}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>DATE OF BIRTH</b></label>
                </div>
                <div className="col-6">
                  <label>- {formatMySQLDate(personal_details[0]?.dob)}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>DATE OF EMPLOYEMENT</b></label>
                </div>
                <div className="col-6">
                  <label>- {formatMySQLDate(personal_details[0]?.doe)}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>DATE OF MARRIED</b></label>
                </div>
                <div className="col-6">
                  <label>- {formatMySQLDate(personal_details[0]?.dom)}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>NAME OF SPOUSE</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.spouse_name}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>FATHER NAME</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.father_name}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>MOTHER NAME</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.mother_name}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>AADHAR CARD</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.aadhar_card}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>PAN CARD</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.pan_card}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>EMAIL ID</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.email_id}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>NAME OF BUDDY</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.name_of_buddy}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>MOBILE NO</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.mobile}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>ADDRESS</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.address}</label>
                </div>
                <div className="col-6">
                  <label><b>CITY</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.city}</label>
                </div>
                <div className="col-6">
                  <label><b>STATE</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.state}</label>
                </div>
                <div className="col-6">
                  <label><b>COUNTRY</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.country}</label>
                </div>
                <div className="col-6">
                  <label><b>PINCODE</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.pincode}</label>
                </div>
              </div>
              <div className='row col-12 m-1'>
                <div className="col-6">
                  <label><b>ADD SPECIALIZATION(TRADE RELATED)</b></label>
                </div>
                <div className="col-6">
                  <label>- {personal_details[0]?.addl_special}</label>
                </div>
              </div>
            </div>
            <div className='col-lg-3' />
            <div className='col-lg-2 col-md-2'>
              <div className='card' style={{ width: '10rem', height: '150px' }}>
                <img src={personal_details[0]?.image_url} width={160} height={150} id="imageFileInput" />
              </div>
            </div>
            <br />
            <br />
            <div className='col-lg-6 col-md-4 mt-4'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th>SNO</th>
                    <th colSpan="2">CAT</th>
                    <th>PART-II NO</th>
                    <th>REMARKS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-th="SNO">1</td>
                    <td data-th="CAT" colSpan="2">MARRIAGE</td>
                    <td data-th="PART-II NO">{personal_details[0]?.marriage_part2_no}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.marriage_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">2</td>
                    <td data-th="CAT" colSpan="2">BIRTH</td>
                    <td data-th="PART-II NO">{formatMySQLDate(personal_details[0]?.birth_part2_no)}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.birth_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">3</td>
                    <td data-th="CAT" colSpan="2">JOINT ACCOUNT</td>
                    <td data-th="PART-II NO">{personal_details[0]?.jt_acct_part2_no}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.jt_acct_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">4</td>
                    <td data-th="CAT" colSpan="2">UID</td>
                    <td data-th="PART-II NO">{personal_details[0]?.uid_part2_no}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.uid_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">5</td>
                    <td data-th="CAT" colSpan="2">PAN CARD</td>
                    <td data-th="PART-II NO">{personal_details[0]?.pan_part2_no}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.pan_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">6</td>
                    <td data-th="CAT" colSpan="2">EMAIL ID</td>
                    <td data-th="PART-II NO">{personal_details[0]?.email_part2_no} </td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.email_remarks}</td>
                  </tr>
                  <tr>
                    <td data-th="SNO">7</td>
                    <td data-th="CAT" colSpan="2">MOBILE PHONE NO</td>
                    <td data-th="PART-II NO">{personal_details[0]?.mobile_part2_no}</td>
                    <td data-th="REMARKS" style={{ textAlign: 'end' }}>{personal_details[0]?.mobile_remarks}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>PUNISHMENTS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">PUNISHMENT</th>
                <th scope="col">DT</th>
                <th scope="col">AA</th>
                <th scope="col">OFFENCE</th>
                <th scope="col">AWARDED BY</th>
              </tr>
            </thead>
            <tbody>
              {
                punishment_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="PUNISHMENT">{item.punishment}</td>
                      <td data-th="DT">{formatMySQLDate(item.dt)}</td>
                      <td data-th="AA">{item.aa}</td>
                      <td data-th="OFFENCE">{item.offence}</td>
                      <td data-th="AWARDED BY">{item.awarded_by}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>COURSES</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">COURSE</th>
                <th scope="col">YEAR</th>
                <th scope="col">GRADING</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                course_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="YEAR">{formatMySQLDate(item.year)}</td>
                      <td data-th="GRADING">{item.grading}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>CADRES</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered  table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">CADRE</th>
                <th scope="col">DURATION</th>
                <th scope="col">RESULT</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                cadres_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="CADRE">{item.cadre}</td>
                      <td data-th="DURATION">{item.duration}</td>
                      <td data-th="RESULT">{item.result}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>ERE'S</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered  table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">NAME OF ERE BN</th>
                <th scope="col">DURATION FROM</th>
                <th scope="col">DURATION TO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                eres_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="NAME OF ERE BN">{item.ere_name}</td>
                      <td data-th="DURATION FROM">{formatMySQLDate(item.duration_from)}</td>
                      <td data-th="DURATION TO">{formatMySQLDate(item.duration_to)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>LOAN DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered  table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">TYPE OF LOAN</th>
                <th scope="col">INTEREST RATE </th>
                <th scope="col">EMI</th>
                <th scope="col">DATE</th>
                <th scope="col">NO OF YEARS</th>
                <th scope="col">LOANING AGENCY</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                personal_loan_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="TYPE OF LOAN">{item.type_of_loan}</td>
                      <td data-th="INTEREST RATE">{item.interest_rate}</td>
                      <td data-th="EMI">{item.emi}</td>
                      <td data-th="DATE">{formatMySQLDate(item.date)}</td>
                      <td data-th="NO OF YEARS">{item.no_of_years}</td>
                      <td data-th="LOANING AGENCY">{item.loaning_agency}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>PROMOTION DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK TYPE</th>
                <th scope="col">RANK YEAR </th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                promotion_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK TYPE">{item.rank_type}</td>
                      <td data-th="RANK DATE">{formatMySQLDate(item.rank_date)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>AFPP DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">TOTAL AMOUNT WITHDRAWAL</th>
                <th scope="col">YEAR </th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                afpp_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="TOTAL AMT WITHDRAWAL">{item.withdrawn_amount}</td>
                      <td data-th="YEAR">{formatMySQLDate(item.year)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>MACP DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">CAT</th>
                <th scope="col">DATE </th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                macp_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="CAT">{item.cat}</td>
                      <td data-th="DATE">{formatMySQLDate(item.cat_date)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>SERVICE RETN BD DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">GRANTED</th>
                <th scope="col">NOT GRANTED </th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                service_retn_bd_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="GRANTED">{item.granted}</td>
                      <td data-th="NOT GRANTED">{item.not_granted}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>BANK DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">NAME OF BANK</th>
                <th scope="col">DT OF OPENING ACCOUNT</th>
                <th scope="col">TYPE OF ACCOUNT</th>
                <th scope="col">ACCOUNT NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                bank_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="NAME OF BANK">{item.bank_name}</td>
                      <td data-th="DT OF OPENING ACCOUNT">{formatMySQLDate(item.acc_opening_date)}</td>
                      <td data-th="TYPE OF ACCOUNT">{item.acc_type}</td>
                      <td data-th="ACCOUNT NO">{item.acc_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>JOINT ACCOUNT DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">NAME OF BANK</th>
                <th scope="col">JOINT ACCOUNT WITH</th>
                <th scope="col">TYPE OF ACCOUNT</th>
                <th scope="col">ACCOUNT NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                joint_account_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="NAME OF BANK">{item.bank_name}</td>
                      <td data-th="JOINT ACCOUNT WITH">{item.joint_acc_with}</td>
                      <td data-th="TYPE OF ACCOUNT">{item.acc_type}</td>
                      <td data-th="ACCOUNT NO">{item.acc_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>BPET/PPT</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">DATE</th>
                <th scope="col">EX</th>
                <th scope="col">GOOD</th>
                <th scope="col">SAT</th>
                <th scope="col">FAIL</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                bpet_ppt_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="DATE">{formatMySQLDate(item.date)}</td>
                      <td data-th="EX">{item.ex}</td>
                      <td data-th="GOOD">{item.good}</td>
                      <td data-th="SAT">{item.sat}</td>
                      <td data-th="FAIL">{item.fail}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>FIRING </b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered  table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">DATE</th>
                <th scope="col">MM</th>
                <th scope="col">FC</th>
                <th scope="col">SS</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                firing_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="DATE">{formatMySQLDate(item.date)}</td>
                      <td data-th="MM">{item.mm}</td>
                      <td data-th="FC">{item.fc}</td>
                      <td data-th="SS">{item.ss}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>HOSPITALIZATION DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">NAME OF HOSPITAL</th>
                <th scope="col">ADMISSION DATE</th>
                <th scope="col">DISCHARGE DATE</th>
                <th scope="col">INJURY REPORT</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                hospitalization_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="NAME OF HOSPITAL">{item.hospital_name}</td>
                      <td data-th="ADMISSION DATE">{formatMySQLDate(item.admission_date)}</td>
                      <td data-th="DISCHARGE DATE">{formatMySQLDate(item.discharge_date)}</td>
                      <td data-th="INJURY REPORT">{item.injury_report}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>INJURY PART-II ORDER DETAILS</b></h6></label>

        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">SUSTAINED INJURY ON</th>
                <th scope="col">INJURY DUE TO</th>
                <th scope="col">ATTR/</th>
                <th scope="col">NOT ATTR</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                injury_part2_order_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="SUSTAINED INJURY ON">{formatMySQLDate(item.sustained_injury_on)}</td>
                      <td data-th="INJURY DUE TO">{item.injury_due_to}</td>
                      <td data-th="ATTR/">{item.attr}</td>
                      <td data-th="NOT ATTR">{item.not_attr}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>LVE DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">LVE TYPE</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                lve_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="LVE TYPE">{item.lve_type}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>ACC LVE DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">NO OF DAYS ACCUMULATED</th>
                <th scope="col">YEAR</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                acc_lve_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="NO OF DAYS ACCUMULATED">{item.no_of_days_accumulated}</td>
                      <td data-th="YEAR">{formatMySQLDate(item.year)}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>MARTIAL DISCORD CASE DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">SINCE</th>
                <th scope="col">NO OF COURT HEARINGS</th>
                <th scope="col">NEXT COURT HEARING DATE</th>
                <th scope="col">COURT OF JUSTICE</th>
                <th scope="col">MAINT ALLCE</th>
              </tr>
            </thead>
            <tbody>
              {
                maritial_discord_case_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="SINCE">{item.since}</td>
                      <td data-th="NO OF COURT HEARINGS">{item.no_of_court_hearings}</td>
                      <td data-th="NEXT COURT HEARING DATE">{formatMySQLDate(item.next_court_hearing_date)}</td>
                      <td data-th="COURT OF JUSTICE">{item.court_of_justice}</td>
                      <td data-th="MAINT ALLCE">{item.maint_allce}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>CIVIL EDN DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">CIVIL EDN TYPE</th>
                <th scope="col">CIVIL EDN YEAR</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                civil_edn_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="CIVIL EDN TYPE">{item.civil_edn_type}</td>
                      <td data-th="CIVIL EDN YEAR">{formatMySQLDate(item.civil_edn_year)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>MIL EDN DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered  table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RANK</th>
                <th scope="col">NAME</th>
                <th scope="col">MIL EDN TYPE</th>
                <th scope="col">MIL EDN YEAR</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                mil_edn_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RANK">{item.rank}</td>
                      <td data-th="NAME">{item.name}</td>
                      <td data-th="MIL EDN TYPE">{item.mil_edn_type}</td>
                      <td data-th="MIL EDN YEAR">{formatMySQLDate(item.mil_edn_year)}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>CANTEEN CARD DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">INDL GROCERY</th>
                <th scope="col">INDL LIQUOR</th>
                <th scope="col">D-I</th>
                <th scope="col">D-II</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>

              </tr>
            </thead>
            <tbody>
              {
                canteen_card_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="INDL GROCERY">{item.indl_grocery}</td>
                      <td data-th="INDL LIQUOR">{item.indl_liquor}</td>
                      <td data-th="D-I">{item.d_i}</td>
                      <td data-th="D-II">{item.d_ii}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>DEPENDENT CARD DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RELATION TYPE</th>
                <th scope="col">RELATION NAME</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                dependent_card_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RELATION TYPE">{item.relation_type}</td>
                      <td data-th="RELATION NAME">{item.relation_name}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>NOK DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">NAME OF NOK</th>
                <th scope="col">DATE OF BIRTH</th>
                <th scope="col">AGE</th>
                <th scope="col">RELATION</th>
                <th scope="col">OCCUPATION</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                nok_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="NAME OF NOK">{item.nok_name}</td>
                      <td data-th="DOB">{formatMySQLDate(item.dob)}</td>
                      <td data-th="AGE">{item.age}</td>
                      <td data-th="RELATION">{item.relation}</td>
                      <td data-th="OCCUPATION">{item.occupation}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>INTERVIEW DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">DATE</th>
                <th scope="col">PL CDR's POINTS</th>
                <th scope="col">COY CDR's POINTS</th>
                <th scope="col">CO's POINTS</th>
                <th scope="col" style={{ textAlign: 'end' }}>REMARKS</th>
              </tr>
            </thead>
            <tbody>
              {
                interview_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="DATE">{item.date}</td>
                      <td data-th="PL CDR's POINTS">{item.pl_cdrs_points}</td>
                      <td data-th="COY CDR's POINTS">{item.coy_cdrs_points}</td>
                      <td data-th="CO's POINTS">{item.cos_points}</td>
                      <td data-th="REMARKS" style={{ textAlign: 'end' }}>{item.remarks}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <label style={{ color: '#5e5e5e' }}><h6><b>FAMILY DETAILS</b></h6></label>
        <div className="table-responsive">
          <table className="rwd-table table table-bordered table-sm ">
            <thead>
              <tr>
                <th scope="col">SNO</th>
                <th scope="col">RELATION TYPE</th>
                <th scope="col">NAME</th>
                <th scope="col">DATE OF BIRTH</th>
                <th scope="col">AGE</th>
                <th scope="col">OCCUPATION</th>
                <th scope="col">QUALIFICATION</th>
                <th scope="col">DEMISE</th>
                <th scope="col">PART-II ORDER NO</th>
                <th scope="col">PHOTO</th>
              </tr>
            </thead>
            <tbody>
              {
                family_details?.map((item, index) => {
                  return (
                    <tr>
                      <td data-th="SNO">{index + 1}</td>
                      <td data-th="RELATION TYPE">{item.relation_type}</td>
                      <td data-th="NAME">{item.relation_name}</td>
                      <td data-th="DOB">{formatMySQLDate(item.dob)}</td>
                      <td data-th="AGE">{item.age}</td>
                      <td data-th="OCCUPATION">{item.occupation}</td>
                      <td data-th="QUALIFICATION">{item.qualification}</td>
                      <td data-th="DEMISE">{item.demise}</td>
                      <td data-th="PART-II ORDER NO">{item.part2_order_no}</td>
                      <td data-th="PHOTO"><img style={{ width: '100px', height: '100px' }} src={item.image_url} /></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </main>
    </div >

  )
}
